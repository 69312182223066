"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultipleDeliveryRadii = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var react_2 = require("@nextui-org/react");
var table_cell_1 = require("../../../../../../../../../components/table/tableCell/table_cell");
var tableContext_1 = require("../../../../../../../../../contexts/tableContext");
var useColumns_1 = require("./hooks/useColumns");
var useRenderCellViews_1 = require("./hooks/useRenderCellViews");
var useRowActions_1 = require("./hooks/useRowActions");
function MultipleDeliveryRadii() {
    var tableRef = (0, react_1.useRef)();
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.branchState; }), isLoading = _a.isLoading, pagination = _a.pagination, selectedBranch = _a.selectedBranch;
    var _b = (0, useColumns_1.useColumns)(), columns = _b.columns, excludeSortColumns = _b.excludeSortColumns, headerColumns = _b.headerColumns, initialSortDescriptor = _b.initialSortDescriptor, onVisibleColumnsChanged = _b.onVisibleColumnsChanged, setTableColumnConfig = _b.setTableColumnConfig, tableColumnConfig = _b.tableColumnConfig, visibleColumns = _b.visibleColumns;
    var renderCellViews = (0, useRenderCellViews_1.useRenderCellViews)().renderCellViews;
    var rowActions = (0, useRowActions_1.useRowActions)().rowActions;
    var multipleRadiiToRender = (0, react_1.useMemo)(function () {
        var _a, _b, _c;
        return (_c = (_b = (_a = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.delivery) === null || _a === void 0 ? void 0 : _a.delivery_radii) === null || _b === void 0 ? void 0 : _b.map(function (_a) {
            var delivery_distance_km = _a.delivery_distance_km, delivery_fee = _a.delivery_fee;
            return ({
                delivery_distance_km: delivery_distance_km,
                delivery_fee: delivery_fee,
            });
        })) !== null && _c !== void 0 ? _c : [];
    }, [selectedBranch]);
    return ((0, jsx_runtime_1.jsxs)(tableContext_1.TableContextProvider, __assign({ columns: columns, defaultQueryParams: pagination, excludeSortColumns: excludeSortColumns, headerColumns: headerColumns, initialSortDescriptor: initialSortDescriptor, onVisibleColumnsChanged: onVisibleColumnsChanged, setTableConfig: setTableColumnConfig, tableColumnConfig: tableColumnConfig, pagination: pagination, visibleColumns: visibleColumns }, { children: [(0, jsx_runtime_1.jsx)("h3", __assign({ className: "text-lg font-[700] leading-[32px] mb-6" }, { children: "Multiple Delivery Radii" })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-full relative overflow-hidden" }, { children: (0, jsx_runtime_1.jsxs)(react_2.Table, __assign({ "aria-label": "Multiple Delivery Radii", classNames: { td: "before:bg-transparent" }, isHeaderSticky: true, ref: tableRef, topContentPlacement: "outside" }, { children: [(0, jsx_runtime_1.jsx)(react_2.TableHeader, __assign({ columns: headerColumns }, { children: function (column) { return (0, jsx_runtime_1.jsx)(react_2.TableColumn, { children: column.name }, column.uid); } })), (0, jsx_runtime_1.jsx)(react_2.TableBody, __assign({ isLoading: isLoading, emptyContent: "This table is empty.", items: multipleRadiiToRender, loadingContent: (0, jsx_runtime_1.jsx)(react_2.Spinner, { label: "Loading..." }) }, { children: function (item) { return ((0, jsx_runtime_1.jsx)(react_2.TableRow, { children: function (columnKey) { return ((0, jsx_runtime_1.jsx)(react_2.TableCell, { children: (0, jsx_runtime_1.jsx)(table_cell_1.TableCellComponent, { columnRecord: item, columnKey: columnKey, renderCellViews: renderCellViews, rowActions: rowActions }) })); } }, !!item.delivery_distance_km ? item.delivery_distance_km : new Date().getTime())); } }))] })) }))] })));
}
exports.MultipleDeliveryRadii = MultipleDeliveryRadii;
