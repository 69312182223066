"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectedChannelsSelectModal = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var react_2 = require("@nextui-org/react");
var get_channels_thunk_1 = require("../../../../../../../../reducers/slices/channels/thunks/get_channels_thunk");
var main_1 = require("../../../../../../../../reducers/slices/main");
var ConnectedChannelsSelectModal = function (_a) {
    var channel_type = _a.channel_type, connectedChannels = _a.connectedChannels, onConfirm = _a.onConfirm;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.channelState; }), channels = _b.channels, loading = _b.loading;
    var _c = (0, react_1.useState)([]), selectedChannels = _c[0], setSelectedChannels = _c[1];
    (0, react_1.useEffect)(function () {
        dispatch((0, get_channels_thunk_1.get_channels_thunk)({ type: channel_type }));
    }, []);
    var filteredChannels = channels.filter(function (channel) { return !connectedChannels.find(function (connectedChannel) { return connectedChannel.channel_id === channel._id; }); });
    var allSelected = connectedChannels.length === (channels === null || channels === void 0 ? void 0 : channels.length) && filteredChannels.length === 0;
    var channelOptions = filteredChannels
        .sort(function (a, b) { return a.type.localeCompare(b.type); })
        .map(function (channel) { return ({
        channel_id: channel._id,
        name: channel.name,
        channel: channel.type.toUpperCase(),
    }); });
    return ((0, jsx_runtime_1.jsxs)(react_2.Card, __assign({ className: "flex flex-col gap-6 justify-between items-center max-w-md p-4" }, { children: [allSelected && (0, jsx_runtime_1.jsx)("p", __assign({ className: "text-default-500 text-center" }, { children: "No more channels available to add to this product." })), loading ? ((0, jsx_runtime_1.jsx)("div", __assign({ className: "flex justify-center w-full" }, { children: (0, jsx_runtime_1.jsx)(react_2.Spinner, { size: "lg" }) }))) : ((0, jsx_runtime_1.jsx)("div", __assign({ className: "w-[250px]" }, { children: (0, jsx_runtime_1.jsx)(react_2.Select, __assign({ label: "Channels", selectionMode: "multiple", placeholder: "Select channels", className: "w-full", selectedKeys: selectedChannels.map(function (ch) { return ch.channel_id; }), onSelectionChange: function (keys) {
                        var selected = Array.from(keys)
                            .map(function (id) { return channelOptions.find(function (ch) { return ch.channel_id === id; }); })
                            .filter(Boolean);
                        setSelectedChannels(selected);
                    } }, { children: channelOptions.map(function (channel) { return ((0, jsx_runtime_1.jsx)(react_2.SelectItem, __assign({ value: channel.channel_id }, { children: channel.name }), channel.channel_id)); }) })) }))), (0, jsx_runtime_1.jsx)("p", __assign({ className: "text-default-500 text-center text-sm" }, { children: "Adding channels to this product will sync this product across the selected channels upon saving this product." })), channel_type === "shopify" && (0, jsx_runtime_1.jsx)("p", __assign({ className: "text-default-500 text-center text-sm" }, { children: "Due to constraints in Shopify, upon." })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-6 justify-center items-center" }, { children: [(0, jsx_runtime_1.jsx)(react_2.Button, __assign({ variant: "bordered", color: "primary", className: "w-[150px]", onClick: function () { return (0, main_1.setContentModal)(null); } }, { children: "Cancel" })), (0, jsx_runtime_1.jsx)(react_2.Button, __assign({ color: "primary", className: "w-[150px]", onClick: function () {
                            onConfirm(selectedChannels);
                            (0, main_1.setContentModal)(null);
                        } }, { children: "Confirm" }))] }))] })));
};
exports.ConnectedChannelsSelectModal = ConnectedChannelsSelectModal;
