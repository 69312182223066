"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var react_1 = require("@nextui-org/react");
var react_redux_1 = require("react-redux");
var main_1 = require("../../../../../../../../reducers/slices/main");
var connected_channels_select_modal_1 = require("./connected_channels_select_modal");
var ConnectedChannelsBoxComponent = function (_a) {
    var logo = _a.logo, channel = _a.channel, _b = _a.channels, channels = _b === void 0 ? [] : _b, updateValues = _a.updateValues;
    var dispatch = (0, react_redux_1.useDispatch)();
    var addProductToChannelModal = function () {
        dispatch((0, main_1.setContentModal)({
            title: "Add Product to Channel",
            content: ((0, jsx_runtime_1.jsx)(connected_channels_select_modal_1.ConnectedChannelsSelectModal, { channel_type: channel, connectedChannels: channels, onConfirm: function (selected_channels) {
                    updateValues("channels", __spreadArray(__spreadArray([], channels, true), selected_channels, true));
                } })),
        }));
    };
    var removeProductFromChannelModal = function (channel_id) {
        dispatch((0, main_1.setContentModal)({
            title: "Remove channel link from product",
            content: ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-6 justify-between text-center items-center max-w-md" }, { children: [(0, jsx_runtime_1.jsx)("p", __assign({ className: "text-default-500" }, { children: "Removing the channel link from this product will only remove the link to the channel and not the product from the selected channel." })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4 justify-center" }, { children: [(0, jsx_runtime_1.jsx)(react_1.Button, __assign({ variant: "bordered", color: "primary", onClick: function () { return (0, main_1.setContentModal)(null); } }, { children: "Cancel" })), (0, jsx_runtime_1.jsx)(react_1.Button, __assign({ color: "primary", onClick: function () {
                                    updateValues("channels", channels.filter(function (channel) { return channel.channel_id !== channel_id; }));
                                } }, { children: "Confirm" }))] }))] }))),
        }));
    };
    return ((0, jsx_runtime_1.jsxs)(react_1.Card, __assign({ className: "flex-1 min-w-[20rem] max-w-xs", shadow: "none" }, { children: [(0, jsx_runtime_1.jsxs)(react_1.CardHeader, __assign({ className: "flex justify-between items-center px-4 py-3" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex items-center gap-2" }, { children: [(0, jsx_runtime_1.jsx)("img", { src: logo, alt: channel, className: "w-8 h-8 object-contain" }), (0, jsx_runtime_1.jsx)("span", __assign({ className: "text-sm font-medium" }, { children: channel.toLocaleUpperCase() }))] })), (0, jsx_runtime_1.jsx)(react_1.Button, __assign({ isIconOnly: true, variant: "light", size: "sm", onClick: addProductToChannelModal }, { children: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faPlus, className: "h-4 w-4" }) }))] })), (0, jsx_runtime_1.jsx)(react_1.Divider, {}), (0, jsx_runtime_1.jsx)(react_1.CardBody, __assign({ className: "px-2 py-1 max-h-48 overflow-y-auto" }, { children: channels === null || channels === void 0 ? void 0 : channels.map(function (channel) {
                    var _a;
                    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-between items-center p-2 hover:bg-default-100 rounded-md group" }, { children: [(0, jsx_runtime_1.jsx)("span", __assign({ className: "text-sm" }, { children: (_a = channel.name) !== null && _a !== void 0 ? _a : channel.channel_id })), (0, jsx_runtime_1.jsx)(react_1.Button, __assign({ isIconOnly: true, variant: "light", size: "sm", className: "opacity-0 group-hover:opacity-100 transition-opacity", onClick: function () { return removeProductFromChannelModal(channel.channel_id); } }, { children: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faXmark, className: "h-4 w-4" }) }))] }), channel.channel_id));
                }) }))] })));
};
exports.default = ConnectedChannelsBoxComponent;
