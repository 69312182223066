"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var reactflow_1 = __importStar(require("reactflow"));
var react_redux_1 = require("react-redux");
var doshx_controls_web_1 = require("doshx_controls_web");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var react_router_dom_1 = require("react-router-dom");
var routes_1 = require("../../../../../constants/routes");
var workflow_1 = require("../../../../../reducers/slices/workflow/workflow");
var update_workflow_thunk_1 = require("../../../../../reducers/slices/workflow/thunks/update_workflow_thunk");
var main_1 = require("../../../../../reducers/slices/main");
var create_workflow_thunk_1 = require("../../../../../reducers/slices/workflow/thunks/create_workflow_thunk");
var constants_1 = require("./constants");
var validateForm_1 = require("../../../../../helpers/validateForm/validateForm");
require("reactflow/dist/base.css");
var WorkflowEditor = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.workflowState; }), selectedWorkflow = _a.selectedWorkflow, newNode = _a.newNode, isLoading = _a.isLoading, isEdited = _a.isEdited, nodes = _a.nodes, edges = _a.edges;
    var reactFlowWrapper = (0, react_1.useRef)(null);
    var _b = (0, react_1.useState)(null), reactFlowInstance = _b[0], setReactFlowInstance = _b[1];
    var _onNodesChange = function (nodeChanges) { return dispatch((0, workflow_1.onNodesChange)(nodeChanges)); };
    var _onEdgesChange = function (edgeChanges) { return dispatch((0, workflow_1.onEdgesChange)(edgeChanges)); };
    var _onConnect = function (connection) { return dispatch((0, workflow_1.onConnect)(connection)); };
    (0, react_1.useEffect)(function () {
        return function () {
            // Clear any content modal open when unmounted, to prevent editing nodes on the wrong page.
            dispatch((0, main_1.setContentModal)(null));
        };
    }, []);
    var validateNodes = function (nodes) {
        var valid = true;
        nodes.forEach(function (node) {
            var getValidationConfig = function (nodeType, nodeName) {
                var _a, _b;
                for (var _i = 0, _c = Object.values(constants_1.nodeConfigurations); _i < _c.length; _i++) {
                    var configurations = _c[_i];
                    var nodeConfig = configurations.find(function (config) { return config.type === nodeType && config.data.name === nodeName; });
                    if (nodeConfig) {
                        return (_b = (_a = nodeConfig.data) === null || _a === void 0 ? void 0 : _a.validation) !== null && _b !== void 0 ? _b : null;
                    }
                }
                return null;
            };
            var validationConfig = getValidationConfig(node.type, node.data.name);
            var errors = (0, validateForm_1.validateForm)(node.data.config, { validationConfig: validationConfig }).errors;
            if (errors && Object.keys(errors).length > 0) {
                valid = false;
            }
            dispatch((0, workflow_1.setNode)(__assign(__assign({}, node), { data: __assign(__assign({}, node.data), { errors: errors }) })));
        });
        return valid;
    };
    var onSave = function (workflow) {
        var valid = validateNodes(nodes);
        if (!valid) {
            dispatch((0, main_1.setMessageModal)({ title: "Validation Error", messages: ["Please fix the validation errors in the nodes before saving"] }));
            return;
        }
        if (workflow._id && workflow.name) {
            dispatch((0, update_workflow_thunk_1.update_workflow_thunk)(workflow));
        }
        else {
            dispatch((0, main_1.setContentModal)({
                title: "Save Workflow",
                content: ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4 justify-center" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Workflow name", size: "small", onChange: function (name) { return (workflow.name = name); } }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row pt-4 gap-4 justify-center" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Cancel", size: "small", backgroundColor: "bg-slate-300", textColor: "text-dark", onClick: function () { return dispatch((0, main_1.setContentModal)(null)); } }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "min-w-[75px]" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Save", size: "small", onClick: function () {
                                            if (workflow._id) {
                                                dispatch((0, update_workflow_thunk_1.update_workflow_thunk)(workflow));
                                            }
                                            else {
                                                dispatch((0, create_workflow_thunk_1.create_workflow_thunk)(workflow));
                                            }
                                            dispatch((0, main_1.setContentModal)(null));
                                        } }) }))] }))] }))),
            }));
        }
    };
    var onSaveAndPublish = function (workflow) {
        dispatch((0, main_1.setContentModal)({
            title: "Save And Publish Workflow",
            content: ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4 justify-center" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Are you sure?", size: "small" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row pt-4 gap-4 justify-center" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Cancel", size: "small", backgroundColor: "bg-slate-300", textColor: "text-dark", onClick: function () { return dispatch((0, main_1.setContentModal)(null)); } }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "min-w-[75px]" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Confirm", size: "small", onClick: function () {
                                        dispatch((0, update_workflow_thunk_1.update_workflow_thunk)(workflow));
                                        dispatch((0, main_1.setContentModal)(null));
                                    } }) }))] }))] }))),
        }));
    };
    var onDragOver = (0, react_1.useCallback)(function (event) {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
    }, []);
    var onDrop = (0, react_1.useCallback)(function (event) {
        event.preventDefault();
        var reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
        var type = event.dataTransfer.getData("application/reactflow");
        if (typeof type === "undefined" || !type) {
            return;
        }
        if (type === "event") {
            if (nodes.some(function (node) { return node.type === "event"; })) {
                dispatch((0, main_1.setMessageModal)({ title: "Limit Reached", messages: ["Only one event node is allowed in a workflow"] }));
                return;
            }
        }
        var position = reactFlowInstance.project({
            x: event.clientX - reactFlowBounds.left,
            y: event.clientY - reactFlowBounds.top,
        });
        var randomID;
        do {
            randomID = doshx_controls_web_1.Utilities.randomAlphanumeric();
        } while (nodes.find(function (node) { return node.id === "".concat(type, "-").concat(randomID); }));
        dispatch((0, workflow_1.setNodes)(__spreadArray(__spreadArray([], nodes, true), [__assign({ id: "".concat(type, "-").concat(randomID), type: type, position: position }, newNode)], false)));
    }, [reactFlowWrapper, nodes, newNode]);
    var makePayload = function (_a) {
        var nodes = _a.nodes, edges = _a.edges, props = __rest(_a, ["nodes", "edges"]);
        return __assign(__assign({}, props), { nodes: nodes.map(function (node) {
                var _a = node.data, schema = _a.schema, data = __rest(_a, ["schema"]);
                return __assign(__assign({}, node), { data: data });
            }), edges: edges });
    };
    return ((0, jsx_runtime_1.jsx)(reactflow_1.ReactFlowProvider, { children: (0, jsx_runtime_1.jsx)("div", __assign({ ref: reactFlowWrapper, className: "flex-1" }, { children: (0, jsx_runtime_1.jsxs)(reactflow_1.default, __assign({ nodes: nodes, edges: edges, onNodesChange: _onNodesChange, onEdgesChange: _onEdgesChange, onConnect: _onConnect, onInit: setReactFlowInstance, onDrop: onDrop, onDragOver: onDragOver, nodeTypes: constants_1.nodeTypes, snapToGrid: true, fitView: true, className: "grow bg-blue-50 border border-blue-100 shadow-inner rounded-lg" }, { children: [(0, jsx_runtime_1.jsx)(reactflow_1.Background, {}), (0, jsx_runtime_1.jsx)(reactflow_1.Controls, {}), (0, jsx_runtime_1.jsx)(reactflow_1.Panel, __assign({ position: "top-left" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row justify-start items-center gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { iconColor: "text-black", label: "Back to workflows", size: "xsmall", startIcon: pro_light_svg_icons_1.faChevronLeft, backgroundColor: "bg-transparent", textColor: "text-black", onClick: function () {
                                        navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.WORKFLOWS_ROUTE));
                                    } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { iconColor: "text-secondary", label: "Save", size: "xsmall", startIcon: pro_light_svg_icons_1.faSave, backgroundColor: "bg-transparent hover:bg-blue-300", textColor: "text-secondary", backgroundDisabledColor: "bg-transparent", onClick: function () {
                                        onSave(makePayload(__assign(__assign({}, selectedWorkflow), { status: "DRAFT", nodes: nodes, edges: edges })));
                                    }, loading: isLoading }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Publish", enabled: selectedWorkflow.status === "DRAFT" || isEdited, size: "xsmall", startIcon: pro_light_svg_icons_1.faArrowCircleRight, backgroundColor: "bg-transparent hover:bg-blue-300", textColor: selectedWorkflow.status === "DRAFT" || isEdited ? "text-secondary" : "text-gray-300", iconColor: selectedWorkflow.status === "DRAFT" || isEdited ? "text-secondary" : "text-gray-300", backgroundDisabledColor: "bg-transparent", onClick: function () {
                                        return onSaveAndPublish(makePayload(__assign(__assign({}, selectedWorkflow), { status: "PUBLISHED", nodes: nodes, edges: edges })));
                                    }, loading: isLoading })] })) })), (0, jsx_runtime_1.jsx)(reactflow_1.Panel, __assign({ position: "top-right", className: "flex flex-col items-end gap-2 p-2 text-labelText" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row gap-2" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Status:", size: "xsmall" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: selectedWorkflow.status, size: "xsmall" })] })) }))] })) })) }));
};
exports.default = WorkflowEditor;
