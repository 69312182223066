"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GiftCategoriesTablePage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var pages_1 = require("../../../../../constants/pages");
var routes_1 = require("../../../../../constants/routes");
var useSearchDebounce_1 = require("../../../../../hooks/useSearchDebounce");
var category_1 = require("../../../../../reducers/slices/categories/category");
var console_1 = require("../../../../../reducers/slices/console");
var gift_1 = require("../../../../../reducers/slices/gift/gift");
var get_gift_categories_thunk_1 = require("../../../../../reducers/slices/gift/thunks/get_gift_categories_thunk");
var GiftCategoriesTablePage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _a = (0, useSearchDebounce_1.useSearchDebounce)(), search = _a[0], setSearch = _a[1];
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.giftState; }), giftCategories = _b.giftCategories, giftCategoriesPagination = _b.giftCategoriesPagination;
    var client_token = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).client_token;
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Gift Categories"));
    }, []);
    (0, react_1.useEffect)(function () {
        if (client_token) {
            dispatch((0, category_1.setLoaded)(true));
            dispatch((0, get_gift_categories_thunk_1.get_gift_categories_thunk)(__assign({}, giftCategoriesPagination)));
        }
    }, [client_token]);
    (0, react_1.useEffect)(function () {
        onSearch(search);
    }, [search]);
    var getGiftCategories = function (query) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, dispatch((0, get_gift_categories_thunk_1.get_gift_categories_thunk)(query))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var onSearch = function (search_string) {
        getGiftCategories(__assign(__assign({}, pages_1.initialPaginationValues), (search_string && { search_string: search_string })));
    };
    var onPageChange = function (page) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, dispatch((0, get_gift_categories_thunk_1.get_gift_categories_thunk)(__assign(__assign({}, giftCategoriesPagination), { page: page })))];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    var onRowsPerPageChange = function (size) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, dispatch((0, get_gift_categories_thunk_1.get_gift_categories_thunk)(__assign(__assign({}, giftCategoriesPagination), { size: size })))];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-end items-center gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Add Gift Category", endIcon: pro_light_svg_icons_1.faPlus, size: "small", backgroundColor: "bg-success", textColor: "text-white", onClick: function () {
                            navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.GIFT_CATEGORY_DETAILS_ROUTE));
                        } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Search", borderColor: "border-borders", backgroundColor: "bg-white", endIcon: pro_solid_svg_icons_1.faSearch, size: "small", onChange: function (e) {
                            setSearch(e);
                        } })] })), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TableControl, { columns: [
                    {
                        displayKey: "image",
                        label: "Image",
                        formatter: function (category) {
                            var _a, _b;
                            if ((_a = category === null || category === void 0 ? void 0 : category.image) === null || _a === void 0 ? void 0 : _a.url) {
                                return (0, jsx_runtime_1.jsx)("img", { className: "w-10 aspect-square rounded-lg", src: (_b = category === null || category === void 0 ? void 0 : category.image) === null || _b === void 0 ? void 0 : _b.url });
                            }
                            else {
                                return "";
                            }
                        },
                    },
                    {
                        displayKey: "name",
                        label: "Category Name",
                    },
                ], data: giftCategories, onRowClicked: function (data) {
                    dispatch((0, gift_1.setSelectedGiftCategory)(data));
                    navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.GIFT_CATEGORY_DETAILS_ROUTE, "/").concat(data === null || data === void 0 ? void 0 : data._id));
                }, rowActions: [
                    {
                        label: "Edit",
                        onClick: function (data) {
                            dispatch((0, gift_1.setSelectedGiftCategory)(data));
                            navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.GIFT_CATEGORY_DETAILS_ROUTE, "/").concat(data === null || data === void 0 ? void 0 : data._id));
                        },
                    },
                ], hasShadow: true, pagination: {
                    rowsPerPageOptions: [10, 15, 20],
                    currentPage: giftCategoriesPagination === null || giftCategoriesPagination === void 0 ? void 0 : giftCategoriesPagination.page,
                    rowsPerPage: giftCategoriesPagination === null || giftCategoriesPagination === void 0 ? void 0 : giftCategoriesPagination.size,
                    totalRecords: giftCategoriesPagination === null || giftCategoriesPagination === void 0 ? void 0 : giftCategoriesPagination.countTotal,
                    onPageChange: onPageChange,
                    onRowsPerPageChange: onRowsPerPageChange,
                }, colors: pages_1.tableColorConfig })] })));
};
exports.GiftCategoriesTablePage = GiftCategoriesTablePage;
