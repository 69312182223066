"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useChangeBranchModalState = void 0;
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var react_hook_form_1 = require("react-hook-form");
var branch_list_thunk_1 = require("../../../../../../../reducers/slices/branch/thunks/get/branch_list_thunk");
var add_order_notes_1 = require("../../../../../../../api/calls/order/update/add_order_notes");
var get_order_thunk_1 = require("../../../../../../../reducers/slices/orders/thunks/get_order_thunk");
var defaultValues = {
    branch: {},
};
function useChangeBranchModalState() {
    var _this = this;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.userState; }), client_token = _a.client_token, user = _a.user;
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.branchState; }), branches = _b.branches, isLoading = _b.isLoading, pagination = _b.pagination;
    var selectedOrder = (0, react_redux_1.useSelector)(function (state) { return state.orderState; }).selectedOrder;
    var _c = (0, react_hook_form_1.useForm)({
        defaultValues: defaultValues,
    }), control = _c.control, reset = _c.reset, watch = _c.watch;
    var branchOptions = (0, react_1.useMemo)(function () { return branches.map(function (branch) { return (!!branch._id ? branch : undefined); }).filter(function (branch) { return !!branch; }); }, [branches]);
    var selectedBranch = watch("branch");
    var onSelectedOrderBranchChanged = (0, react_1.useCallback)(function () { return __awaiter(_this, void 0, void 0, function () {
        var targetBranch, _id, display_name, extra_data, message, response;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    targetBranch = branches.find(function (_a) {
                        var _id = _a._id;
                        return selectedBranch === _id;
                    });
                    if (!targetBranch) return [3 /*break*/, 3];
                    _id = targetBranch._id, display_name = targetBranch.display_name, extra_data = targetBranch.extra_data;
                    message = "".concat(user.name, " ").concat(user.surname, " changed branch from ").concat((_b = (_a = selectedOrder === null || selectedOrder === void 0 ? void 0 : selectedOrder.branch) === null || _a === void 0 ? void 0 : _a.display_name) !== null && _b !== void 0 ? _b : "undefined", " to ").concat(targetBranch.display_name);
                    return [4 /*yield*/, (0, add_order_notes_1.add_order_notes)({
                            order_id: selectedOrder === null || selectedOrder === void 0 ? void 0 : selectedOrder._id,
                            user: {
                                _id: user === null || user === void 0 ? void 0 : user._id,
                                display_name: "".concat(user.name, " ").concat(user.surname),
                            },
                            message: message,
                            date: "".concat(new Date()),
                            branch_id: _id,
                        })];
                case 1:
                    response = _c.sent();
                    if (!response.success) return [3 /*break*/, 3];
                    return [4 /*yield*/, dispatch((0, get_order_thunk_1.get_order_thunk)({ _id: selectedOrder._id }))];
                case 2:
                    _c.sent();
                    _c.label = 3;
                case 3: return [2 /*return*/, targetBranch];
            }
        });
    }); }, [branches, dispatch, selectedBranch, selectedOrder, user]);
    (0, react_1.useEffect)(function () {
        if (client_token && !!!isLoading) {
            if (branches.length < 1) {
                dispatch((0, branch_list_thunk_1.branch_list_thunk)(__assign({}, pagination)));
            }
        }
    }, [branches, client_token, dispatch, isLoading, pagination]);
    (0, react_1.useEffect)(function () {
        if (!!selectedOrder) {
            reset({
                branch: selectedOrder.branch,
            });
        }
    }, [reset, selectedOrder]);
    return (0, react_1.useMemo)(function () { return ({
        branchOptions: branchOptions,
        control: control,
        onSelectedOrderBranchChanged: onSelectedOrderBranchChanged,
        selectedBranch: selectedBranch,
    }); }, [branchOptions, control, onSelectedOrderBranchChanged, selectedBranch]);
}
exports.useChangeBranchModalState = useChangeBranchModalState;
