"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRowActions = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var pro_solid_svg_icons_2 = require("@fortawesome/pro-solid-svg-icons");
var react_2 = require("@nextui-org/react");
function useRowActions(_a) {
    var onOpenDrawer = _a.onOpenDrawer;
    var dispatch = (0, react_redux_1.useDispatch)();
    var selectedBranch = (0, react_redux_1.useSelector)(function (state) { return state.branchState; }).selectedBranch;
    var enterReferenceRef = (0, react_1.useRef)(null);
    var viewRef = (0, react_1.useRef)(null);
    var getEnterReferenceProps = (0, react_2.useButton)({ ref: enterReferenceRef }).getButtonProps;
    var getViewProps = (0, react_2.useButton)({ ref: viewRef }).getButtonProps;
    var enterReferenceAction = (0, react_1.useCallback)(function (entry) { return function () { }; }, [selectedBranch]);
    var viewAction = (0, react_1.useCallback)(function (entry) { return function () {
        onOpenDrawer(entry);
    }; }, [onOpenDrawer]);
    var rowActions = (0, react_1.useCallback)(function (row) { return [
        {
            element: ((0, jsx_runtime_1.jsx)(react_2.Tooltip, __assign({ className: "text-foreground", content: "View Details" }, { children: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, __assign({}, getViewProps(), { onClick: viewAction(row), className: "cursor-pointer text-default-400", height: 18, icon: pro_solid_svg_icons_2.faEye, width: 18 })) }))),
            key: "delete",
        },
        {
            element: ((0, jsx_runtime_1.jsx)(react_2.Tooltip, __assign({ className: "text-foreground", content: "Enter Reference" }, { children: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, __assign({}, getEnterReferenceProps(), { onClick: enterReferenceAction(row), className: "cursor-pointer text-default-400", height: 18, icon: pro_solid_svg_icons_1.faCheckToSlot, width: 18 })) }))),
            key: "delete",
        },
    ]; }, [enterReferenceRef, getEnterReferenceProps, getViewProps, enterReferenceAction]);
    return (0, react_1.useMemo)(function () { return ({
        rowActions: rowActions,
    }); }, [rowActions]);
}
exports.useRowActions = useRowActions;
