"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BadgeControl = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
function BadgeControl(props) {
    var value = props.value, _a = props.backgroundColor, backgroundColor = _a === void 0 ? "bg-blue-500" : _a, _b = props.size, size = _b === void 0 ? "md" : _b, _c = props.textColor, textColor = _c === void 0 ? "text-white" : _c, _d = props.className, className = _d === void 0 ? "" : _d;
    var textSizeMap = {
        xs: "text-xs",
        sm: "text-sm",
        md: "text-md",
        lg: "text-lg",
        xl: "text-xl",
        "2xl": "text-2xl",
    };
    var badgeSizeMap = {
        xs: "p-1 rounded-sm",
        sm: "p-2 rounded-md",
        md: "p-3 rounded-lg",
        lg: "p-4 rounded-xl",
        xl: "p-5 rounded-xl",
        "2xl": "p-6 rounded-xl",
    };
    return (0, jsx_runtime_1.jsx)("div", __assign({ className: "".concat(badgeSizeMap[size], " ").concat(backgroundColor, " ").concat(textSizeMap[size], " ").concat(textColor, " ").concat(className) }, { children: value }));
}
exports.BadgeControl = BadgeControl;
