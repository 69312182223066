"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderControls = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var react_2 = require("@nextui-org/react");
var main_1 = require("../../../../../../../reducers/slices/main");
var client_user_selector_1 = require("../../../../../../../selectors/client_user_selector");
var constants_1 = require("../../../constants");
function OrderControls(_a) {
    var onCreateParcel = _a.onCreateParcel, onCancelOrder = _a.onCancelOrder, onApproveLoan = _a.onApproveLoan, onPackToParcel = _a.onPackToParcel, selectedProducts = _a.selectedProducts;
    var dispatch = (0, react_redux_1.useDispatch)();
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var isManager = (0, react_redux_1.useSelector)(client_user_selector_1.isManagerSelector).isManager;
    var isAdmin = (0, react_redux_1.useSelector)(client_user_selector_1.isAdminSelector);
    var parcels = (0, react_redux_1.useSelector)(function (state) { return state.parcelState; }).parcels;
    var selectedOrder = (0, react_redux_1.useSelector)(function (state) { return state.orderState; }).selectedOrder;
    var branchId = (0, react_1.useMemo)(function () { var _a, _b, _c; return (_b = (_a = selectedOrder.branch) === null || _a === void 0 ? void 0 : _a._id) !== null && _b !== void 0 ? _b : (_c = selectedOrder.store) === null || _c === void 0 ? void 0 : _c.store_id; }, [selectedOrder]);
    var isAwaitingApproval = (0, react_1.useMemo)(function () { return selectedOrder.status === constants_1.StatusEnum.WAITING_LOAN_APPROVAL; }, [selectedOrder]);
    var canCancelOrders = (0, react_1.useMemo)(function () { var _a; return isAdmin || (isManager && ((_a = client === null || client === void 0 ? void 0 : client.branch_manager) === null || _a === void 0 ? void 0 : _a.can_cancel_orders)); }, [client, isAdmin, isManager]);
    var canManageParcels = (0, react_1.useMemo)(function () { var _a; return (selectedOrder.status === constants_1.StatusEnum.POS_ORDERED ? (_a = client === null || client === void 0 ? void 0 : client.order_manager) === null || _a === void 0 ? void 0 : _a.is_pos_fulfillment_enabled : !isAwaitingApproval); }, [client, isAwaitingApproval]);
    var approveLoan = (0, react_1.useCallback)(function () {
        dispatch((0, main_1.setContentModal)({
            title: "Confirm",
            content: ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4 flex-col" }, { children: [(0, jsx_runtime_1.jsx)("span", __assign({ className: "w-full text-xl" }, { children: "Are you sure you want to approve loan on this order" })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-2 flex-row" }, { children: [(0, jsx_runtime_1.jsx)(react_2.Button, __assign({ color: "secondary", onClick: onCancelModal, size: "sm" }, { children: "Cancel" })), (0, jsx_runtime_1.jsx)(react_2.Button, __assign({ color: "primary", onClick: onApproveLoan, size: "sm" }, { children: "Yes, Continue" }))] }))] }))),
        }));
    }, []);
    var cancelOrder = (0, react_1.useCallback)(function () {
        dispatch((0, main_1.setContentModal)({
            title: "Confirm",
            content: ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4 flex-col" }, { children: [(0, jsx_runtime_1.jsx)("span", __assign({ className: "w-full text-xl" }, { children: "Are you sure you want to cancel order ".concat(selectedOrder.code, "?") })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-2 flex-row" }, { children: [(0, jsx_runtime_1.jsx)(react_2.Button, __assign({ color: "secondary", onClick: onCancelModal, size: "sm" }, { children: "Cancel" })), (0, jsx_runtime_1.jsx)(react_2.Button, __assign({ color: "primary", onClick: onCancelOrder, size: "sm" }, { children: "Yes, Continue" }))] }))] }))),
        }));
    }, []);
    var packParcel = (0, react_1.useCallback)(function () {
        dispatch((0, main_1.setContentModal)({
            title: "Choose Parcel",
            content: ((0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-wrap gap-4" }, { children: Array.from(selectedProducts).length > 0 ? (parcels === null || parcels === void 0 ? void 0 : parcels.map(function (parcel, index) {
                    var _a;
                    var parcelStatus = parcel === null || parcel === void 0 ? void 0 : parcel.status;
                    if (parcelStatus === constants_1.StatusEnum.OPEN) {
                        return ((0, jsx_runtime_1.jsx)(react_2.Button, __assign({ color: "primary", onClick: onPackToParcel(parcel._id), size: "sm" }, { children: "".concat(parcel.code, " (").concat((_a = parcel.product_list) === null || _a === void 0 ? void 0 : _a.length, ")") })));
                    }
                    else {
                        return null;
                    }
                })) : ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)("h3", __assign({ className: "font-bold text-lg" }, { children: "Please add at least 1 product to your parcel" })) })) }))),
        }));
    }, [selectedProducts]);
    var onCancelModal = (0, react_1.useCallback)(function () {
        dispatch((0, main_1.setContentModal)(null));
    }, []);
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "w-full flex justify-end mt-4" }, { children: selectedOrder.status !== constants_1.StatusEnum.CANCELLED && ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4" }, { children: [isAwaitingApproval && ((0, jsx_runtime_1.jsx)(react_2.Button, __assign({ color: "primary", onClick: approveLoan, size: "sm" }, { children: "Approve Loan" }))), canCancelOrders && ((0, jsx_runtime_1.jsx)(react_2.Button, __assign({ color: "primary", onClick: cancelOrder }, { children: "Cancel Order" }))), canManageParcels && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(react_2.Button, __assign({ color: "primary", onClick: onCreateParcel }, { children: "Create Parcel" })), (0, jsx_runtime_1.jsx)(react_2.Button, __assign({ color: "primary", onClick: packParcel }, { children: "Pack to Parcel" }))] }))] }))) })));
}
exports.OrderControls = OrderControls;
