"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasketsPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var routes_1 = require("../../../../../constants/routes");
var console_1 = require("../../../../../reducers/slices/console");
var basket_table_1 = require("./basket_table");
var create_basket_1 = require("./create_basket");
var view_basket_1 = require("./view_basket");
var BasketsPage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Baskets"));
    }, []);
    // useEffect(() => {
    //   dispatch(
    //     client_basket_list_thunk({
    //       status: basketStatus == "ALL" ? "" : basketStatus,
    //       page: basketPagination.page.toString(),
    //       size: basketPagination.size.toString(),
    //       from_date: basketFilterRange.fromDate, //"2023-04-15",
    //       to_date: basketFilterRange.toDate, //"2023-04-17",
    //       type: basketType == "BOTH" ? "" : basketType,
    //       min_value: basketFilterdByTotal.minValue,
    //       max_value: basketFilterdByTotal.maxValue,
    //       branch_id: branchId ?? "",
    //     }),
    //   );
    // }, [basketPagination.page, basketPagination.size, basketStatus, basketType, basketFilterdByTotal, basketFilterRange, branchId]);
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "w-full h-full" }, { children: (0, jsx_runtime_1.jsxs)(react_router_dom_1.Routes, { children: [(0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: "/*", element: (0, jsx_runtime_1.jsx)(basket_table_1.BasketTable, {}) }), (0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: "/view-baskets/:basketID/:userID", element: (0, jsx_runtime_1.jsx)(view_basket_1.ViewBasketPage, {}) }), (0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: "".concat(routes_1.CREATE_BASKET), element: (0, jsx_runtime_1.jsx)(create_basket_1.CreateBasket, {}) })] }) })));
};
exports.BasketsPage = BasketsPage;
