"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePaginationContext = exports.PaginationContextProvider = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var createUseContext_1 = require("../helpers/createUseContext");
var tableContext_1 = require("./tableContext");
function usePaginationContextData(_a) {
    var _this = this;
    var pagination = _a.pagination;
    var _b = (0, tableContext_1.useTableContext)(), onQueryParamsChanged = _b.onQueryParamsChanged, queryParams = _b.queryParams;
    var _c = (0, react_1.useState)(pagination.page), currentPage = _c[0], setCurrentPage = _c[1];
    var totalPages = pagination.countTotal;
    var pages = (0, react_1.useMemo)(function () {
        var remainder = pagination.countTotal % pagination.size;
        if (remainder > 0) {
            return Math.round(pagination.countTotal / pagination.size);
        }
        return 1;
    }, [pagination]);
    var onNextPage = (0, react_1.useCallback)(function () {
        onPageChange(currentPage + 1);
    }, [currentPage]);
    var onPageChange = (0, react_1.useCallback)(function (page) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setCurrentPage(page);
            onQueryParamsChanged(__assign(__assign(__assign({}, queryParams), pagination), { page: page }));
            return [2 /*return*/];
        });
    }); }, [pagination, queryParams]);
    var onPreviousPage = (0, react_1.useCallback)(function () {
        onPageChange(currentPage - 1);
    }, [currentPage]);
    var setPage = (0, react_1.useCallback)(function (pageIndex) {
        onPageChange(pageIndex);
    }, []);
    return (0, react_1.useMemo)(function () { return ({
        currentPage: currentPage,
        onNextPage: onNextPage,
        onPreviousPage: onPreviousPage,
        pages: pages,
        setPage: setPage,
        totalPages: totalPages,
    }); }, [currentPage, onNextPage, onPreviousPage, pages, setPage, totalPages]);
}
var PaginationContext = (0, react_1.createContext)(null);
PaginationContext.displayName = "PaginationContext";
function PaginationContextProvider(props) {
    var children = props.children;
    return (0, jsx_runtime_1.jsx)(PaginationContext.Provider, __assign({ value: usePaginationContextData(props) }, { children: children }));
}
exports.PaginationContextProvider = PaginationContextProvider;
exports.usePaginationContext = (0, createUseContext_1.createUseContext)(PaginationContext);
