"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var react_1 = require("@nextui-org/react");
var PimProductCompleteness = function (_a) {
    var productId = _a.productId, refreshCompleteness = _a.refreshCompleteness, completeness = _a.completeness, requiredFields = _a.requiredFields;
    var hasRequiredFields = [requiredFields === null || requiredFields === void 0 ? void 0 : requiredFields.base.length, requiredFields === null || requiredFields === void 0 ? void 0 : requiredFields.attributes.length, Object.keys((requiredFields === null || requiredFields === void 0 ? void 0 : requiredFields.channels) || {}).length].some(Boolean);
    var getStatusColor = function (percentage) {
        if (percentage >= 90)
            return "success";
        if (percentage >= 70)
            return "warning";
        return "danger";
    };
    var renderCompletenessCard = function (title, percentage) { return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "gap-4" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-between items-center mb-1" }, { children: [(0, jsx_runtime_1.jsx)("p", __assign({ className: "text-sm text-gray-500" }, { children: title })), (0, jsx_runtime_1.jsxs)(react_1.Chip, __assign({ color: getStatusColor(percentage), variant: "flat" }, { children: [percentage === null || percentage === void 0 ? void 0 : percentage.toFixed(0), "%"] }))] })), (0, jsx_runtime_1.jsx)(react_1.Progress, { color: getStatusColor(percentage), value: percentage, radius: "full", size: "sm" })] }))); };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-6" }, { children: [(0, jsx_runtime_1.jsxs)(react_1.Card, { children: [(0, jsx_runtime_1.jsxs)(react_1.CardHeader, __assign({ className: "flex justify-between items-center px-6 py-4" }, { children: [(0, jsx_runtime_1.jsx)("h3", __assign({ className: "text-lg font-semibold" }, { children: "Product Completeness Overview" })), (0, jsx_runtime_1.jsx)(react_1.Button, __assign({ color: "primary", variant: "solid", startContent: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faRefresh }), onClick: function () { return refreshCompleteness(); } }, { children: "Refresh" }))] })), (0, jsx_runtime_1.jsx)(react_1.Divider, {}), (0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "space-y-4 gap-5 px-5 py-5" }, { children: [renderCompletenessCard("Base Product Information", completeness === null || completeness === void 0 ? void 0 : completeness.base), (requiredFields === null || requiredFields === void 0 ? void 0 : requiredFields.attributes.length) > 0 && renderCompletenessCard("Attributes", completeness === null || completeness === void 0 ? void 0 : completeness.attributes)] })), Object.keys((requiredFields === null || requiredFields === void 0 ? void 0 : requiredFields.channels) || {}).length > 0 && ((0, jsx_runtime_1.jsxs)(react_1.Table, __assign({ isHeaderSticky: true, "aria-label": "Channel Completeness", shadow: "none", bottomContentPlacement: "outside", topContentPlacement: "inside", className: "overflow-hidden", topContent: (0, jsx_runtime_1.jsx)(react_1.CardHeader, __assign({ className: "p-1 m-0" }, { children: (0, jsx_runtime_1.jsx)("p", __assign({ className: "text-sm font-bold" }, { children: "Channel Completeness" })) })), classNames: {
                                    td: "before:bg-transparent",
                                } }, { children: [(0, jsx_runtime_1.jsxs)(react_1.TableHeader, { children: [(0, jsx_runtime_1.jsx)(react_1.TableColumn, { children: "CHANNEL" }), (0, jsx_runtime_1.jsx)(react_1.TableColumn, { children: "COMPLETENESS" }), (0, jsx_runtime_1.jsx)(react_1.TableColumn, { children: "STATUS" })] }), (0, jsx_runtime_1.jsx)(react_1.TableBody, { children: Object.entries((completeness === null || completeness === void 0 ? void 0 : completeness.channels) || {}).map(function (_a) {
                                            var key = _a[0], value = _a[1];
                                            return ((0, jsx_runtime_1.jsxs)(react_1.TableRow, { children: [(0, jsx_runtime_1.jsx)(react_1.TableCell, __assign({ className: "capitalize" }, { children: key })), (0, jsx_runtime_1.jsxs)(react_1.TableCell, { children: [value.toFixed(0), "%"] }), (0, jsx_runtime_1.jsx)(react_1.TableCell, { children: (0, jsx_runtime_1.jsx)(react_1.Chip, __assign({ color: getStatusColor(value), variant: "flat", size: "sm" }, { children: value >= 90 ? "Ready" : value >= 70 ? "In Progress" : "Incomplete" })) })] }, key));
                                        }) })] })))] })] }), hasRequiredFields && ((0, jsx_runtime_1.jsx)(react_1.Card, { children: (0, jsx_runtime_1.jsx)(react_1.Accordion, { children: (0, jsx_runtime_1.jsxs)(react_1.AccordionItem, __assign({ className: "font-semibold px-4", "aria-label": "Completeness Details", title: "Completion Requirements" }, { children: [(0, jsx_runtime_1.jsx)(react_1.Divider, {}), (0, jsx_runtime_1.jsx)("div", __assign({ className: "space-y-4" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-3" }, { children: [(requiredFields === null || requiredFields === void 0 ? void 0 : requiredFields.base.length) > 0 && ((0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(react_1.CardHeader, __assign({ className: "px-0" }, { children: (0, jsx_runtime_1.jsx)("h4", __assign({ className: "text-md font-semibold" }, { children: "Base Product Information" })) })), (0, jsx_runtime_1.jsx)(react_1.CardBody, __assign({ className: "px-0" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "space-y-2" }, { children: requiredFields === null || requiredFields === void 0 ? void 0 : requiredFields.base.map(function (value) {
                                                            return ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)("ul", __assign({ className: "space-y-1" }, { children: (0, jsx_runtime_1.jsxs)("li", __assign({ className: "bg-gray-100 rounded-md px-3 py-2 items-center text-sm  text-gray-600" }, { children: [(0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faInputPipe, className: "mr-2" }), value === null || value === void 0 ? void 0 : value.label] })) })) }));
                                                        }) })) }))] })), (requiredFields === null || requiredFields === void 0 ? void 0 : requiredFields.attributes.length) > 0 && ((0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(react_1.CardHeader, { children: (0, jsx_runtime_1.jsx)("h4", __assign({ className: "text-md font-semibold" }, { children: "Attributes" })) }), (0, jsx_runtime_1.jsx)(react_1.CardBody, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "space-y-2" }, { children: requiredFields === null || requiredFields === void 0 ? void 0 : requiredFields.attributes.map(function (value) { return ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)("ul", __assign({ className: "space-y-1" }, { children: (0, jsx_runtime_1.jsxs)("li", __assign({ className: "bg-gray-100 rounded-md px-3 py-2 items-center text-sm text-gray-600" }, { children: [(0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faInputPipe, className: "mr-2" }), value === null || value === void 0 ? void 0 : value.label] })) })) })); }) })) })] })), Object.keys((requiredFields === null || requiredFields === void 0 ? void 0 : requiredFields.channels) || {}).length > 0 && ((0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(react_1.CardHeader, { children: (0, jsx_runtime_1.jsx)("h4", __assign({ className: "text-md font-semibold" }, { children: "Channels" })) }), (0, jsx_runtime_1.jsx)(react_1.CardBody, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "space-y-2" }, { children: Object.entries((requiredFields === null || requiredFields === void 0 ? void 0 : requiredFields.channels) || {}).map(function (_a) {
                                                            var key = _a[0], value = _a[1];
                                                            return ((0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)("p", __assign({ className: "text-sm font-regular mb-3 capitalize -mt-2" }, { children: key.toLowerCase() })), (0, jsx_runtime_1.jsx)("ul", __assign({ className: "space-y-1" }, { children: value.map(function (val) { return ((0, jsx_runtime_1.jsxs)("li", __assign({ className: "bg-gray-100 rounded-md px-3 py-2 items-center text-sm  text-gray-600" }, { children: [(0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faInputPipe, className: "mr-2" }), val.label] }))); }) }))] }));
                                                        }) })) })] }))] })) }))] }), "details") }) }))] })));
};
exports.default = PimProductCompleteness;
