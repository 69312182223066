"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterTypeEnum = void 0;
var FilterTypeEnum;
(function (FilterTypeEnum) {
    FilterTypeEnum["Tabs"] = "tabs";
    FilterTypeEnum["PriceRange"] = "price_range";
    FilterTypeEnum["TagGroup"] = "tag_group";
    FilterTypeEnum["CheckboxGroup"] = "checkbox_group";
    FilterTypeEnum["RadioGroup"] = "radio_group";
    FilterTypeEnum["Select"] = "select";
    FilterTypeEnum["Input"] = "input";
    FilterTypeEnum["Toggle"] = "toggle";
    FilterTypeEnum["Color"] = "color";
})(FilterTypeEnum = exports.FilterTypeEnum || (exports.FilterTypeEnum = {}));
