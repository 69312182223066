"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableCellComponent = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
function TableCellComponent(_a) {
    var columnRecord = _a.columnRecord, columnKey = _a.columnKey, rowActions = _a.rowActions, renderCellViews = _a.renderCellViews;
    var columnRecordKey = columnKey;
    var cellValue = columnRecord[columnRecordKey];
    if (columnRecordKey !== "actions") {
        var cellView = renderCellViews(cellValue).find(function (cValue) { return columnRecordKey === (cValue === null || cValue === void 0 ? void 0 : cValue.key); });
        if (cellView) {
            return cellView === null || cellView === void 0 ? void 0 : cellView.content;
        }
        else {
            return (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: cellValue }));
        }
    }
    else {
        if (!!rowActions) {
            var rowAction = rowActions(columnRecord);
            return (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex items-center justify-end gap-2" }, { children: rowAction.map(function (_a) {
                    var element = _a.element;
                    return element;
                }) }));
        }
        return null;
    }
}
exports.TableCellComponent = TableCellComponent;
