"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialNotificationValues = exports.EVENT_TOPICS = exports.currenciesOptions = void 0;
exports.currenciesOptions = [
    { value: "R", label: "🇿🇦 ZAR" },
    { value: "$", label: "🇺🇸 USD" },
    { value: "€", label: "🇪🇺 EUR" },
    { value: "₹", label: "🇮🇳 INR" },
    { value: "£", label: "🇬🇧 GBP" },
    { value: "¢", label: "🇬🇭 GHS" },
    { value: "Rp", label: "🇮🇩 IDR" },
    { value: "J$", label: "🇯🇲 JMD" },
    { value: "¥", label: "🇯🇵 JPY" },
    { value: "ден", label: "🇲🇰 MKD" },
    { value: "₱", label: "🇵🇭 PHP" },
    { value: "zł", label: "🇵🇱 PLN" },
    { value: "RM", label: "🇲🇾 MYR" },
    { value: "₽", label: "🇷🇺 RUB" },
    { value: "S$", label: "🇸🇬 SGD" },
    { value: "CHF", label: "🇨🇭 CHF" },
    { value: "NT$", label: "🇹🇼 TWD" },
    { value: "฿", label: "🇹🇭 THB" },
    { value: "₺", label: "🇹🇷 TRY" },
    { value: "AED", label: "🇦🇪 AED" },
    { value: "₴", label: "🇺🇦 UAH" },
    { value: "₫", label: "🇻🇳 VND" },
];
// Please note the root keys (and prefix for topic) are the model name as seen in mongodb
exports.EVENT_TOPICS = {
    users: {
        CREATED: "users/created",
        UPDATED: "users/updated",
    },
    baskets: {
        CREATED: "baskets/created",
        UPDATED: "baskets/updated",
        TIMEOUT: "baskets/timeout",
    },
    orders: {
        CREATED: "orders/created",
        VIEWED: "orders/viewed",
        UPDATED: "orders/updated",
        STATUS_CHANGE: "orders/status-change",
    },
    parcels: {
        CREATED: "parcels/created",
        PACKED: "parcels/packed",
        FULFILLED: "parcels/fulfilled",
        UPDATED: "parcels/updated",
        STATUS_CHANGE: "parcels/status-change",
    },
    loyaltyprograms: {
        CREATED: "loyaltyprograms/created",
        UPDATED: "loyaltyprograms/updated",
    },
};
exports.initialNotificationValues = { enabled: false, returns_email_address: "" };
