"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddonsTable = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_2 = require("@nextui-org/react");
var table_bottom_content_1 = require("../../../../../../components/table/tableBottomContent/table_bottom_content");
var table_cell_1 = require("../../../../../../components/table/tableCell/table_cell");
var tableContext_1 = require("../../../../../../contexts/tableContext");
var useColumns_1 = require("./hooks/useColumns");
var useRenderCellViews_1 = require("./hooks/useRenderCellViews");
var useRowActions_1 = require("./hooks/useRowActions");
function AddonsTable(_a) {
    var selectedProduct = _a.selectedProduct;
    var tableRef = (0, react_1.useRef)();
    var _b = (0, useColumns_1.useColumns)(), columns = _b.columns, excludeSortColumns = _b.excludeSortColumns, headerColumns = _b.headerColumns, initialSortDescriptor = _b.initialSortDescriptor, onVisibleColumnsChanged = _b.onVisibleColumnsChanged, setTableColumnConfig = _b.setTableColumnConfig, tableColumnConfig = _b.tableColumnConfig, visibleColumns = _b.visibleColumns;
    var renderCellViews = (0, useRenderCellViews_1.useRenderCellViews)().renderCellViews;
    var rowActions = (0, useRowActions_1.useRowActions)().rowActions;
    var _c = (0, react_1.useState)(1), currentPage = _c[0], setCurrentPage = _c[1];
    var _d = (0, react_1.useState)(10), pageSize = _d[0], setPageSize = _d[1];
    var pagination = (0, react_1.useMemo)(function () { var _a; return ({ countTotal: (_a = selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct.variations) === null || _a === void 0 ? void 0 : _a.length, page: currentPage, size: pageSize }); }, [selectedProduct, currentPage, pageSize]);
    var variantsToRender = (0, react_1.useMemo)(function () {
        var _a, _b;
        if (!selectedProduct || !selectedProduct.add_ons) {
            return [];
        }
        return ((_b = (_a = selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct.add_ons) === null || _a === void 0 ? void 0 : _a.map(function (add_on) { return (__assign(__assign({}, add_on), { image: add_on.image_url, variant: add_on.title })); })) !== null && _b !== void 0 ? _b : []);
    }, [selectedProduct]);
    return ((0, jsx_runtime_1.jsx)(tableContext_1.TableContextProvider, __assign({ columns: columns, defaultQueryParams: pagination, excludeSortColumns: excludeSortColumns, headerColumns: headerColumns, initialSortDescriptor: initialSortDescriptor, onVisibleColumnsChanged: onVisibleColumnsChanged, setTableConfig: setTableColumnConfig, tableColumnConfig: tableColumnConfig, pagination: pagination, visibleColumns: visibleColumns }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "w-full relative overflow-hidden" }, { children: [(0, jsx_runtime_1.jsx)("h3", __assign({ className: "text-xl mb-4 mt-2 px-4" }, { children: "Add Ons" })), (0, jsx_runtime_1.jsxs)(react_2.Table, __assign({ "aria-label": "Add Ons", bottomContent: variantsToRender.length > 10 ? (0, jsx_runtime_1.jsx)(table_bottom_content_1.TableBottomContent, {}) : null, bottomContentPlacement: "outside", classNames: { td: "before:bg-transparent" }, isHeaderSticky: true, ref: tableRef, selectionMode: "single", topContentPlacement: "outside" }, { children: [(0, jsx_runtime_1.jsx)(react_2.TableHeader, __assign({ columns: headerColumns }, { children: function (column) { return (0, jsx_runtime_1.jsx)(react_2.TableColumn, { children: column.name }, column.uid); } })), (0, jsx_runtime_1.jsx)(react_2.TableBody, __assign({ isLoading: false, emptyContent: "This table is empty.", items: variantsToRender, loadingContent: (0, jsx_runtime_1.jsx)(react_2.Spinner, { label: "Loading..." }) }, { children: function (item) { return ((0, jsx_runtime_1.jsx)(react_2.TableRow, { children: function (columnKey) { return ((0, jsx_runtime_1.jsx)(react_2.TableCell, { children: (0, jsx_runtime_1.jsx)(table_cell_1.TableCellComponent, { columnRecord: item, columnKey: columnKey, renderCellViews: renderCellViews, rowActions: rowActions }) })); } }, !!item._id ? item._id : new Date().getTime())); } }))] }))] })) })));
}
exports.AddonsTable = AddonsTable;
