"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRenderCellViews = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
function CoordinatesCell(_a) {
    var cellValue = _a.cellValue;
    var coordinatesValue = (0, react_1.useMemo)(function () { return "[".concat(cellValue.map(function (_a) {
        var lat = _a.lat, lng = _a.lng;
        return "".concat(lat, ", ").concat(lng);
    }).join(" | "), "]"); }, [cellValue]);
    return (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: coordinatesValue }));
}
function useRenderCellViews() {
    var renderCellViews = (0, react_1.useCallback)(function (cellValue) {
        return [
            { key: "name", content: (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: cellValue })) },
            { key: "coordinates", content: (0, jsx_runtime_1.jsx)(CoordinatesCell, { cellValue: cellValue }) },
        ];
    }, []);
    return (0, react_1.useMemo)(function () { return ({
        renderCellViews: renderCellViews,
    }); }, [renderCellViews]);
}
exports.useRenderCellViews = useRenderCellViews;
