"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRenderCellViews = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_2 = require("@nextui-org/react");
var formatting_1 = require("lets-trade-utils/lib/utils/formatting");
var status_component_1 = require("../../../../../../../components/status_component");
function ImageCellView(_a) {
    var cellValue = _a.cellValue;
    return (0, jsx_runtime_1.jsx)(react_2.Image, { src: cellValue, height: 40, width: 40 });
}
function MoneyCellView(_a) {
    var cellValue = _a.cellValue;
    if (!cellValue) {
        return (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: "0.00" }));
    }
    return (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: (0, formatting_1.formatMoney)(cellValue) }));
}
function useRenderCellViews() {
    var renderCellViews = (0, react_1.useCallback)(function (cellValue) {
        return [
            { key: "image", content: (0, jsx_runtime_1.jsx)(ImageCellView, { cellValue: cellValue }) },
            { key: "variant", content: (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: cellValue })) },
            { key: "sku", content: (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: cellValue })) },
            { key: "quantity", content: (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: cellValue })) },
            { key: "price", content: (0, jsx_runtime_1.jsx)(MoneyCellView, { cellValue: cellValue }) },
            { key: "on_promotion", content: (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: cellValue })) },
            { key: "status", content: (0, jsx_runtime_1.jsx)(status_component_1.Status, { status: cellValue }) },
        ];
    }, []);
    return (0, react_1.useMemo)(function () { return ({
        renderCellViews: renderCellViews,
    }); }, [renderCellViews]);
}
exports.useRenderCellViews = useRenderCellViews;
