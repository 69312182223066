"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableTopContent = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var react_2 = require("@iconify/react");
var react_3 = require("@nextui-org/react");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var routes_1 = require("../../../../../../constants/routes");
var drawerContext_1 = require("../../../../../../contexts/drawerContext");
var filtersContext_1 = require("../../../../../../contexts/filtersContext");
var paginationContext_1 = require("../../../../../../contexts/paginationContext");
var tableContext_1 = require("../../../../../../contexts/tableContext");
var main_1 = require("../../../../../../reducers/slices/main");
function TableTopContent(_a) {
    var selectedTableCells = _a.selectedTableCells;
    var _b = (0, filtersContext_1.useFiltersContext)(), isFiltersPopoverOpen = _b.isFiltersPopoverOpen, onApplyFilters = _b.onApplyFilters, onClearFilters = _b.onClearFilters, onCloseFilterPopover = _b.onCloseFilterPopover, onOpenFilterPopover = _b.onOpenFilterPopover;
    var toggleDrawer = (0, drawerContext_1.useDrawerContext)().toggleDrawer;
    var setPage = (0, paginationContext_1.usePaginationContext)().setPage;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _c = (0, tableContext_1.useTableContext)(), allowSorting = _c.allowSorting, columns = _c.columns, headerColumns = _c.headerColumns, onSearchChanged = _c.onSearchChanged, onSortChanged = _c.onSortChanged, onVisibleColumnsChanged = _c.onVisibleColumnsChanged, setTableConfig = _c.setTableConfig, sortDescriptor = _c.sortDescriptor, tableColumnConfig = _c.tableColumnConfig, visibleColumns = _c.visibleColumns;
    var handleApplyFilters = (0, react_1.useCallback)(function () {
        onApplyFilters();
    }, [onApplyFilters]);
    var handleClearFilters = (0, react_1.useCallback)(function () {
        onClearFilters();
    }, [onClearFilters]);
    var handleOpenChanged = (0, react_1.useCallback)(function () {
        if (!isFiltersPopoverOpen) {
            onOpenFilterPopover();
        }
        else {
            onCloseFilterPopover();
        }
    }, [isFiltersPopoverOpen, onCloseFilterPopover, onOpenFilterPopover]);
    var handleSearch = (0, react_1.useCallback)(function (searchString) {
        onSearchChanged(searchString);
        if (searchString === "") {
            setPage(1);
        }
    }, [onSearchChanged, setPage]);
    var onPushToUber = function () {
        dispatch((0, main_1.setContentModal)(null));
        navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.CHANNELS_ROUTE, "/uber"));
    };
    var handleSortChanged = (0, react_1.useCallback)(function (item) { return function () {
        onSortChanged({
            column: item.uid,
            direction: sortDescriptor.direction === "ascending" ? "descending" : "ascending",
        });
    }; }, []);
    var handleVisibleColumnsChanged = (0, react_1.useCallback)(function (keys) {
        var visibleColumns = ["actions"];
        var keysArray = Array.from(keys);
        for (var i = 0; i < keysArray.length; i++) {
            visibleColumns.push(keysArray[i]);
        }
        setTableConfig(__assign(__assign({}, tableColumnConfig), { visibleColumns: visibleColumns }));
        onVisibleColumnsChanged(keys);
    }, [onVisibleColumnsChanged, setTableConfig, tableColumnConfig]);
    var selectedActions = [
        {
            label: "Push to Uber",
            key: "push_to_uber",
            onClick: onPushToUber,
        },
    ];
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "flex items-center overflow-auto" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex items-center gap-3" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex items-center gap-4" }, { children: [(0, jsx_runtime_1.jsx)(react_3.Input, { className: "min-w-[200px]", endContent: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faSearch, className: "text-default-400", width: 16 }), placeholder: "Search", size: "sm", onValueChange: handleSearch }), (0, jsx_runtime_1.jsx)(react_3.Button, __assign({ className: "bg-default-100 text-default-800", endContent: (0, jsx_runtime_1.jsx)(react_2.Icon, { className: "text-default-400", icon: "solar:alt-arrow-down-linear" }), size: "sm", variant: "flat", onClick: toggleDrawer }, { children: "Filter" })), allowSorting && ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsxs)(react_3.Dropdown, { children: [(0, jsx_runtime_1.jsx)(react_3.DropdownTrigger, { children: (0, jsx_runtime_1.jsx)(react_3.Button, __assign({ className: "bg-default-100 text-default-800", size: "sm", startContent: (0, jsx_runtime_1.jsx)(react_2.Icon, { className: "text-default-400", icon: "solar:sort-linear", width: 16 }) }, { children: "Sort" })) }), (0, jsx_runtime_1.jsx)(react_3.DropdownMenu, __assign({ "aria-label": "Sort", items: headerColumns.filter(function (_a) {
                                        var sortable = _a.sortable;
                                        return sortable === true;
                                    }) }, { children: function (item) { return ((0, jsx_runtime_1.jsx)(react_3.DropdownItem, __assign({ onPress: handleSortChanged(item) }, { children: item.name }), item.uid)); } }))] }) })), (0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsxs)(react_3.Dropdown, __assign({ closeOnSelect: false }, { children: [(0, jsx_runtime_1.jsx)(react_3.DropdownTrigger, { children: (0, jsx_runtime_1.jsx)(react_3.Button, __assign({ className: "bg-default-100 text-default-800", size: "sm", startContent: (0, jsx_runtime_1.jsx)(react_2.Icon, { className: "text-default-400", icon: "solar:sort-horizontal-linear", width: 16 }) }, { children: "Columns" })) }), (0, jsx_runtime_1.jsx)(react_3.DropdownMenu, __assign({ disallowEmptySelection: true, "aria-label": "Columns", items: columns.filter(function (c) { return !["actions"].includes(c.uid); }), selectedKeys: visibleColumns, selectionMode: "multiple", onSelectionChange: handleVisibleColumnsChanged }, { children: function (item) { return (0, jsx_runtime_1.jsx)(react_3.DropdownItem, { children: item.name }, item.uid); } }))] })) }), (0, jsx_runtime_1.jsx)("div", { children: (selectedTableCells === "all" || selectedTableCells.size > 0) && ((0, jsx_runtime_1.jsxs)(react_3.Dropdown, { children: [(0, jsx_runtime_1.jsx)(react_3.DropdownTrigger, { children: (0, jsx_runtime_1.jsx)(react_3.Button, __assign({ className: "bg-default-100 text-default-800", endContent: (0, jsx_runtime_1.jsx)(react_2.Icon, { className: "text-default-400", icon: "solar:alt-arrow-down-linear" }), size: "sm", variant: "flat" }, { children: "Selected Actions" })) }), (0, jsx_runtime_1.jsx)(react_3.DropdownMenu, __assign({ "aria-label": "Selected Actions" }, { children: selectedActions.length > 0 &&
                                        selectedActions.map(function (action) { return ((0, jsx_runtime_1.jsx)(react_3.DropdownItem, __assign({ onClick: action.onClick }, { children: action.label }), action.key)); }) }))] })) })] })) })) })));
}
exports.TableTopContent = TableTopContent;
