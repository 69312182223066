"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var main_1 = require("../../../../reducers/slices/main");
var react_2 = require("@nextui-org/react");
var react_3 = require("@iconify/react");
var endpoint_data_mapper_preview_1 = require("./endpoint_data_mapper_preview");
var APISchemaBuilder = function (_a) {
    var value = _a.value, onChange = _a.onChange;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _b = (0, react_1.useState)(0), view = _b[0], setView = _b[1];
    var schemaCopy = value.length > 0 ? value.map(function (i) { return (__assign({}, i)); }) : null;
    var _c = (0, react_1.useState)(schemaCopy !== null && schemaCopy !== void 0 ? schemaCopy : [
        {
            fieldName: "",
            fieldType: "String",
            required: false,
            defaultValue: "",
            nestedFields: [],
            errorText: {},
        },
    ]), fields = _c[0], setFields = _c[1];
    var addField = function () {
        var previousField = fields[fields.length - 1];
        if (fields.length > 0)
            validateField(previousField);
        setFields(__spreadArray(__spreadArray([], fields, true), [
            {
                fieldName: "",
                fieldType: "String",
                required: false,
                defaultValue: "",
                nestedFields: [],
                errorText: {},
            },
        ], false));
    };
    var removeField = function (index) {
        var updatedFields = fields.filter(function (_, i) { return i !== index; });
        setFields(updatedFields);
    };
    var updateField = function (index, fieldName, fieldValue) {
        var updatedFields = fields.map(function (field, i) {
            var _a;
            return (i === index ? __assign(__assign({}, field), (_a = {}, _a[fieldName] = fieldValue, _a)) : field);
        });
        setFields(updatedFields);
    };
    var addNestedField = function (parentIndex) {
        var updatedFields = fields.map(function (field, i) {
            var _a;
            return i === parentIndex
                ? __assign(__assign({}, field), { fieldType: "Array", nestedFields: __spreadArray(__spreadArray([], ((_a = field.nestedFields) !== null && _a !== void 0 ? _a : []), true), [
                        {
                            fieldName: "",
                            fieldType: "String",
                            required: false,
                            defaultValue: "",
                            nestedFields: [],
                            errorText: {},
                        },
                    ], false) }) : field;
        });
        setFields(updatedFields);
    };
    var removeNestedField = function (parentIndex, nestedIndex) {
        var updatedFields = fields.map(function (field, i) {
            return i === parentIndex
                ? __assign(__assign({}, field), { nestedFields: field.nestedFields.filter(function (_, j) { return j !== nestedIndex; }), fieldType: field.nestedFields.length === 1 ? "String" : field.fieldType }) : field;
        });
        setFields(updatedFields);
    };
    var updateNestedField = function (parentIndex, nestedIndex, fieldName, fieldValue) {
        var updatedFields = fields.map(function (field, i) {
            return i === parentIndex
                ? __assign(__assign({}, field), { nestedFields: field.nestedFields.map(function (nestedField, j) {
                        var _a;
                        return (j === nestedIndex ? __assign(__assign({}, nestedField), (_a = {}, _a[fieldName] = fieldValue, _a)) : nestedField);
                    }) }) : field;
        });
        setFields(updatedFields);
    };
    var fieldDataTypeOptions = [
        { label: "String", value: "String" },
        { label: "Number", value: "Number" },
        { label: "Boolean", value: "Boolean" },
        { label: "Date", value: "Date" },
        { label: "Integer", value: "Integer" },
        { label: "Array", value: "Array" },
    ];
    var validateField = function (field) {
        var validation = {
            fieldName: !field.fieldName ? "Field name is required" : "",
            fieldType: !field.fieldType ? "Field type is required" : "",
        };
        updateField(fields.indexOf(field), "errorText", validation);
        if (validation.fieldName || validation.fieldType)
            return false;
        return true;
    };
    var validateFields = function () {
        var isValid = true;
        fields.forEach(function (field) {
            if (!field.errorText)
                isValid = validateField(field);
            else if (field.errorText.fieldName || field.errorText.fieldType)
                isValid = false;
        });
        if (isValid)
            fields.forEach(function (field) {
                delete field.errorText;
            });
        return isValid;
    };
    var FieldMap = function (_a) {
        var _b, _c;
        var field = _a.field, index = _a.index, _d = _a.parentIndex, parentIndex = _d === void 0 ? null : _d;
        return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4 ".concat(parentIndex !== null ? "ml-8" : "pt-2") }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row items-center gap-4" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "min-w-lg" }, { children: (0, jsx_runtime_1.jsx)(react_2.Input, { label: "Target Field", type: "text", variant: "bordered", color: "primary", errorMessage: (_b = field.errorText) === null || _b === void 0 ? void 0 : _b.fieldName, value: field.fieldName, onChange: function (e) {
                                    var value = e.target.value;
                                    if (parentIndex === null)
                                        updateField(index, "fieldName", value);
                                    else
                                        updateNestedField(parentIndex, index, "fieldName", value);
                                } }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-[150px]" }, { children: (0, jsx_runtime_1.jsx)(react_2.Select, __assign({ label: "Type", isDisabled: field.nestedFields && field.nestedFields.length > 0, items: fieldDataTypeOptions, selectedKeys: [field.fieldType], onSelectionChange: function (keys) {
                                    var value = Array.from(keys)[0];
                                    if (parentIndex === null) {
                                        if (value === "Array")
                                            addNestedField(index);
                                        else
                                            updateField(index, "fieldType", value);
                                    }
                                    else
                                        updateNestedField(parentIndex, index, "fieldType", value);
                                } }, { children: function (item) { return (0, jsx_runtime_1.jsx)(react_2.SelectItem, { children: item.label }, item.value); } })) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "min-w-lg" }, { children: (0, jsx_runtime_1.jsx)(react_2.Input, { disabled: field.nestedFields && field.nestedFields.length > 0, label: "Default Value", type: "text", variant: "bordered", color: field.nestedFields && field.nestedFields.length > 0 ? "default" : "primary", value: field.defaultValue, onChange: function (e) {
                                    var value = e.target.value;
                                    if (parentIndex === null)
                                        updateField(index, "defaultValue", value);
                                    else
                                        updateNestedField(parentIndex, index, "defaultValue", value);
                                } }) })), parentIndex === null && ((0, jsx_runtime_1.jsx)(react_2.Button, __assign({ isIconOnly: true, size: "lg", className: "text-black bg-transparent hover:bg-gray-100", onClick: function () {
                                addNestedField(index);
                            } }, { children: (0, jsx_runtime_1.jsx)(react_3.Icon, { icon: "solar:add-circle-outline", width: "24", height: "24" }) }))), (0, jsx_runtime_1.jsx)(react_2.Button, __assign({ isIconOnly: true, size: "lg", className: "text-black bg-transparent hover:bg-gray-100", onClick: function () {
                                if (parentIndex === null)
                                    removeField(index);
                                else
                                    removeNestedField(parentIndex, index);
                            } }, { children: (0, jsx_runtime_1.jsx)(react_3.Icon, { icon: "solar:close-circle-outline", width: "24", height: "24" }) }))] })), ((_c = field.nestedFields) === null || _c === void 0 ? void 0 : _c.length) > 0 &&
                    field.nestedFields.map(function (nestedField, nestedIndex) { return FieldMap({ field: nestedField, index: nestedIndex, parentIndex: index }); })] }), index));
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsxs)(react_2.Tabs, __assign({ "aria-label": "Table", selectedKey: view.toString(), onSelectionChange: function (key) { return setView(parseInt(key.toString())); } }, { children: [(0, jsx_runtime_1.jsx)(react_2.Tab, __assign({ title: "Table" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col gap-4 max-h-36 md:max-h-56 lg:max-h-96 xl:max-h-[500px] overflow-auto" }, { children: fields.map(function (field, parentIndex) { return FieldMap({ field: field, index: parentIndex }); }) })) }), "0"), (0, jsx_runtime_1.jsx)(react_2.Tab, __assign({ title: "Preview" }, { children: (0, jsx_runtime_1.jsx)(endpoint_data_mapper_preview_1.EndpointPreview, { fields: fields }) }), "1")] })), (0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex justify-center" }, { children: (0, jsx_runtime_1.jsx)(react_2.Button, __assign({ isIconOnly: true, size: "lg", onClick: addField, className: "text-black bg-transparent hover:bg-gray-100" }, { children: (0, jsx_runtime_1.jsx)(react_3.Icon, { icon: "solar:add-circle-outline", width: "24", height: "24" }) })) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-[100px] justify-start" }, { children: (0, jsx_runtime_1.jsx)(react_2.Button, __assign({ color: "primary", size: "lg", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    if (!validateFields())
                                        return [2 /*return*/];
                                    onChange(fields);
                                    dispatch((0, main_1.setContentModal)(null));
                                    return [2 /*return*/];
                                });
                            }); } }, { children: "Save" })) }))] })] })));
};
exports.default = APISchemaBuilder;
