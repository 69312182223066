"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRenderCellViews = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_redux_1 = require("react-redux");
var react_1 = require("react");
var react_2 = require("@nextui-org/react");
var doshx_controls_web_1 = require("doshx_controls_web");
var channelsCellComponent_1 = require("../../../../../../../components/channelsCellComponent");
function useRenderCellViews() {
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var renderCellViews = (0, react_1.useCallback)(function (cellValue) {
        var _a, _b, _c;
        var commonTextStyles = "text-small capitalize text-default-foreground";
        var nowrapTextStyles = "text-nowrap text-small capitalize text-default-foreground";
        return [
            {
                key: "image_url",
                content: ((0, jsx_runtime_1.jsx)(react_2.User, { avatarProps: {
                        radius: "lg",
                        src: cellValue,
                    }, classNames: {
                        name: "text-default-foreground",
                        description: "text-default-500",
                    }, name: "" })),
            },
            {
                key: "sku",
                content: (0, jsx_runtime_1.jsx)("div", { children: cellValue }),
            },
            {
                key: "pid",
                content: (0, jsx_runtime_1.jsx)("div", { children: cellValue }),
            },
            {
                key: "title",
                content: (0, jsx_runtime_1.jsx)("div", __assign({ className: nowrapTextStyles }, { children: cellValue })),
            },
            {
                key: "price",
                content: ((0, jsx_runtime_1.jsx)("div", __assign({ className: nowrapTextStyles }, { children: doshx_controls_web_1.Utilities.formatMoney({
                        symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                        value: (_a = cellValue === null || cellValue === void 0 ? void 0 : cellValue.toString()) !== null && _a !== void 0 ? _a : "0",
                        symbolSeparation: true,
                    }) }))),
            },
            {
                key: "category",
                content: (0, jsx_runtime_1.jsx)("div", __assign({ className: commonTextStyles }, { children: (_b = cellValue === null || cellValue === void 0 ? void 0 : cellValue.name) !== null && _b !== void 0 ? _b : "N/A" })),
            },
            {
                key: "on_sale_price",
                content: ((0, jsx_runtime_1.jsx)("div", __assign({ className: commonTextStyles }, { children: doshx_controls_web_1.Utilities.formatMoney({
                        symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                        value: (_c = cellValue === null || cellValue === void 0 ? void 0 : cellValue.toString()) !== null && _c !== void 0 ? _c : "0",
                        symbolSeparation: true,
                    }) }))),
            },
            {
                key: "on_sale",
                content: (0, jsx_runtime_1.jsx)("div", __assign({ className: nowrapTextStyles }, { children: cellValue ? "Yes" : "No" })),
            },
            {
                key: "channels",
                content: (0, jsx_runtime_1.jsx)("div", __assign({ className: "float-start flex gap-1" }, { children: (0, channelsCellComponent_1.ChannelsCellComponent)({ channels: cellValue }) })),
            },
            {
                key: "published",
                content: (0, jsx_runtime_1.jsx)("div", __assign({ className: nowrapTextStyles }, { children: cellValue ? "Yes" : "No" })),
            },
            {
                key: "marketplace_product_status",
                content: ((0, jsx_runtime_1.jsx)(react_2.Chip, __assign({ className: "text-xs capitalize text-default-foreground", size: "md", variant: "flat", color: cellValue === "PENDING" ? "warning" : cellValue === "APPROVED" ? "success" : "danger" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "p-1" }, { children: cellValue })) }))),
            },
        ];
    }, [client]);
    return (0, react_1.useMemo)(function () { return ({ renderCellViews: renderCellViews }); }, [renderCellViews]);
}
exports.useRenderCellViews = useRenderCellViews;
