"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setPagination = exports.setLoading = exports.setSelectedMarketplaceProduct = exports.setClientMarketplace = exports.setJoinedMarketplaces = exports.setMarketplaces = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var pages_1 = require("../../../constants/pages");
var initialState = {
    marketplaces: [],
    joinedMarketplaces: [],
    clientMarketplace: {},
    selectedMarketplaceProduct: {},
    loading: false,
    pagination: pages_1.initialPaginationValues,
};
var slice = (0, toolkit_1.createSlice)({
    name: "marketplaces",
    initialState: initialState,
    reducers: {
        setMarketplaces: function (state, action) {
            state.marketplaces = action.payload;
        },
        setJoinedMarketplaces: function (state, action) {
            state.joinedMarketplaces = action.payload;
        },
        setClientMarketplace: function (state, action) {
            state.clientMarketplace = action.payload;
        },
        setSelectedMarketplaceProduct: function (state, action) {
            state.selectedMarketplaceProduct = action.payload;
        },
        setPagination: function (state, action) {
            state.pagination = action.payload;
        },
        setLoading: function (state, action) {
            state.loading = action.payload;
        },
    },
});
exports.setMarketplaces = (_a = slice.actions, _a.setMarketplaces), exports.setJoinedMarketplaces = _a.setJoinedMarketplaces, exports.setClientMarketplace = _a.setClientMarketplace, exports.setSelectedMarketplaceProduct = _a.setSelectedMarketplaceProduct, exports.setLoading = _a.setLoading, exports.setPagination = _a.setPagination;
exports.default = slice.reducer;
