"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFilterActions = void 0;
var react_1 = require("react");
function useFilterActions(_a) {
    var orders = _a.orders;
    var _b = (0, react_1.useState)("ALL"), selectedStatusFilter = _b[0], setSelectedStatusFilter = _b[1];
    var filters = (0, react_1.useMemo)(function () {
        var orderFilters = orders
            .reduce(function (accumulator, currentValue) {
            if (accumulator.includes(currentValue.status)) {
                return accumulator;
            }
            return __spreadArray(__spreadArray([], accumulator, true), [currentValue.status], false);
        }, [])
            .map(function (filter) { return ({ label: filter, value: filter.toLowerCase() }); });
        return __spreadArray([{ label: "ALL", value: "ALL" }], orderFilters, true);
    }, [orders]);
    var onSetSelectedStatusType = (0, react_1.useCallback)(function (value) {
        setSelectedStatusFilter(value);
    }, [filters]);
    var filterActions = (0, react_1.useMemo)(function () { return [
        {
            key: "status",
            label: "Status",
            value: selectedStatusFilter,
            items: filters,
            onChange: onSetSelectedStatusType,
        },
    ]; }, [filters, onSetSelectedStatusType, selectedStatusFilter]);
    var resetFilters = (0, react_1.useCallback)(function () {
        setSelectedStatusFilter("ALL");
    }, []);
    (0, react_1.useEffect)(function () {
        resetFilters();
    }, []);
    return (0, react_1.useMemo)(function () { return ({
        filterActions: filterActions,
    }); }, [filterActions]);
}
exports.useFilterActions = useFilterActions;
