"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableTopBar = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("@iconify/react");
var react_2 = require("@nextui-org/react");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var routes_1 = require("../../../../../../constants/routes");
var paginationContext_1 = require("../../../../../../contexts/paginationContext");
var main_1 = require("../../../../../../reducers/slices/main");
var importCSV_1 = require("../../components/product_details/importCSV");
var TableTopBar = function () {
    var navigate = (0, react_router_dom_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var totalPages = (0, paginationContext_1.usePaginationContext)().totalPages;
    var pageTitle = (0, react_redux_1.useSelector)(function (state) { return state.consoleState; }).pageTitle;
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var actionButtons = [
        {
            label: "Types",
            iconClassname: "iconamoon:link-thin",
            onClick: function () {
                navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.PRODUCT_TYPES_ROUTE));
            },
        },
        {
            label: "Categories",
            iconClassname: "iconamoon:link-thin",
            onClick: function () {
                navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.PRODUCT_CATEGORIES_ROUTE));
            },
        },
        (user === null || user === void 0 ? void 0 : user.role) === "ADMIN" && {
            label: "Import CSV",
            iconClassname: "material-symbols-light:csv-outline-rounded",
            onClick: function () {
                dispatch((0, main_1.setContentModal)({
                    title: "Import Products",
                    content: (0, jsx_runtime_1.jsx)(importCSV_1.ImportCSVComponent, {}),
                }));
            },
        },
        {
            label: "Add Products",
            iconClassname: "solar:add-circle-bold",
            onClick: function () {
                navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.PIM_DETAILS_ROUTE));
            },
        },
    ];
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "mb-[18px] flex items-center justify-between" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex w-[400px] items-center gap-2" }, { children: [(0, jsx_runtime_1.jsx)("h1", __assign({ className: "text-2xl font-[700] leading-[32px]" }, { children: pageTitle })), (0, jsx_runtime_1.jsx)(react_2.Chip, __assign({ className: "hidden items-center text-default-500 sm:flex", size: "sm", variant: "flat" }, { children: totalPages }))] })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex justify-end gap-4" }, { children: actionButtons.map(function (button, index) { return ((0, jsx_runtime_1.jsx)(react_2.Button, __assign({ color: "primary", onClick: button.onClick, endContent: (0, jsx_runtime_1.jsx)(react_1.Icon, { icon: button.iconClassname, width: 20 }) }, { children: button.label }), index)); }) }))] })));
};
exports.TableTopBar = TableTopBar;
