"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRowActions = void 0;
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
function useRowActions() {
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _a = (0, react_1.useState)(), expandedRow = _a[0], setExpandedRow = _a[1];
    var rowActions = (0, react_1.useCallback)(function (cellValue) { return [
    // {
    //   key: "expand",
    //   onClick: (row: any) => { setExpandedRow(row); },
    // },
    ]; }, []);
    return (0, react_1.useMemo)(function () { return ({
        expandedRow: expandedRow,
        rowActions: rowActions,
    }); }, [expandedRow, rowActions]);
}
exports.useRowActions = useRowActions;
