"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setIsEdited = exports.setOrder = exports.setIsLoading = exports.setTablePagination = exports.setSelectedOrderFirstTimeView = exports.setSelectedOrder = exports.setNewOrderCount = exports.setOrders = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var pages_1 = require("../../../constants/pages");
var initialState = {
    orders: [],
    selectedOrder: {},
    selectedOrderFirstTimeView: false,
    isEdited: false,
    isLoading: false,
    order: {},
    pagination: pages_1.initialPaginationValues,
    newOrderCount: undefined,
};
var slice = (0, toolkit_1.createSlice)({
    name: "orders",
    initialState: initialState,
    reducers: {
        setSelectedMenuConcept: function (state, action) {
            state.selectedOrder = action.payload;
        },
        setOrders: function (state, action) {
            state.orders = action.payload;
        },
        setSelectedOrder: function (state, action) {
            state.selectedOrder = action.payload;
        },
        setSelectedOrderFirstTimeView: function (state, action) {
            state.selectedOrderFirstTimeView = action.payload;
        },
        setIsEdited: function (state, action) {
            state.isEdited = action.payload;
        },
        setIsLoading: function (state, action) {
            state.isLoading = action.payload;
        },
        setOrder: function (state, action) {
            state.order = action.payload;
        },
        setTablePagination: function (state, action) {
            state.pagination = action.payload;
        },
        setNewOrderCount: function (state, action) {
            state.newOrderCount = action.payload;
        },
    },
});
exports.setOrders = (_a = slice.actions, _a.setOrders), exports.setNewOrderCount = _a.setNewOrderCount, exports.setSelectedOrder = _a.setSelectedOrder, exports.setSelectedOrderFirstTimeView = _a.setSelectedOrderFirstTimeView, exports.setTablePagination = _a.setTablePagination, exports.setIsLoading = _a.setIsLoading, exports.setOrder = _a.setOrder, exports.setIsEdited = _a.setIsEdited;
exports.default = slice.reducer;
