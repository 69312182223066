"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createUseContext = void 0;
var react_1 = require("react");
function createUseContext(context) {
    return function () {
        var value = (0, react_1.useContext)(context);
        if (!value) {
            var displayNameFragment = context.displayName ? ": ".concat(context.displayName) : "";
            throw new Error("No value provided. Please provide a value for this context ".concat(displayNameFragment, " - You're most likely accessing it before it is initialized..."));
        }
        return value;
    };
}
exports.createUseContext = createUseContext;
