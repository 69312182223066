"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Drawer = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("@nextui-org/react");
function Drawer(_a) {
    var children = _a.children, _b = _a.isOpen, isOpen = _b === void 0 ? false : _b, onOpenChange = _a.onOpenChange;
    return ((0, jsx_runtime_1.jsx)(react_1.Modal, __assign({ scrollBehavior: "inside", isOpen: isOpen, onOpenChange: onOpenChange, placement: "center", backdrop: "opaque", role: "dialog", size: "full", classNames: {
            wrapper: "flex justify-end",
        }, motionProps: {
            variants: {
                enter: {
                    x: 0,
                    opacity: 1,
                    transition: {
                        duration: 0.3,
                        ease: "easeOut",
                    },
                },
                exit: {
                    x: 50,
                    opacity: 0,
                    transition: {
                        duration: 0.2,
                        ease: "easeIn",
                    },
                },
            },
        }, className: "rounded-md max-w-sm w-full h-screen max-h-screen" }, { children: (0, jsx_runtime_1.jsx)(react_1.ModalContent, __assign({ className: "max-w-7xl" }, { children: children })) })));
}
exports.Drawer = Drawer;
