"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRowActionsProductRequest = void 0;
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var main_1 = require("../../../../../../../reducers/slices/main");
var routes_1 = require("../../../../../../../constants/routes");
var useRowActionsProductRequest = function () {
    var navigate = (0, react_router_dom_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_1.useState)(new Set([])), selectedTableCells = _a[0], setSelectedTableCells = _a[1];
    var supplier_products = (0, react_redux_1.useSelector)(function (state) { return state.supplierRequestState; }).supplier_products;
    var onSelectionChange = function (keys) {
        if (keys === "all") {
            setSelectedTableCells(keys);
            return;
        }
        var resultKeys = new Set(keys);
        var currentKeys = selectedTableCells === "all" ? new Set(supplier_products === null || supplier_products === void 0 ? void 0 : supplier_products.map(function (item) { return String(item === null || item === void 0 ? void 0 : item._id); })) : selectedTableCells;
        currentKeys === null || currentKeys === void 0 ? void 0 : currentKeys.forEach(function (key) {
            if (!(supplier_products === null || supplier_products === void 0 ? void 0 : supplier_products.some(function (item) { return String(item === null || item === void 0 ? void 0 : item._id) === key; }))) {
                resultKeys === null || resultKeys === void 0 ? void 0 : resultKeys.add(key);
            }
        });
        setSelectedTableCells(resultKeys);
    };
    var rowActions = function (cellValue) { return [
        {
            key: "view",
            onClick: function () { return navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.PIM_DETAILS_ROUTE, "/").concat(cellValue === null || cellValue === void 0 ? void 0 : cellValue._id, "/").concat(cellValue === null || cellValue === void 0 ? void 0 : cellValue.client_id), { state: { markup_price: cellValue === null || cellValue === void 0 ? void 0 : cellValue.markup_price } }); },
        },
        {
            key: "delete",
            onClick: function () { return dispatch((0, main_1.setContentModal)(null)); },
        },
    ]; };
    return (0, react_1.useMemo)(function () { return ({ rowActions: rowActions, selectedTableCells: selectedTableCells, onSelectionChange: onSelectionChange }); }, [rowActions]);
};
exports.useRowActionsProductRequest = useRowActionsProductRequest;
