"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableCellComponent = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_2 = require("@nextui-org/react");
var delete_1 = require("./icons/delete");
var edit_1 = require("./icons/edit");
var eye_1 = require("./icons/eye");
var TableCellComponent = function (_a) {
    var columnRecord = _a.columnRecord, columnKey = _a.columnKey, rowActions = _a.rowActions, renderCellViews = _a.renderCellViews, setModifiedRecords = _a.setModifiedRecords;
    var columnRecordKey = columnKey;
    var cellValue = columnRecord[columnRecordKey];
    var eyesRef = (0, react_1.useRef)(null);
    var editRef = (0, react_1.useRef)(null);
    var deleteRef = (0, react_1.useRef)(null);
    var getEyesProps = (0, react_2.useButton)({ ref: eyesRef }).getButtonProps;
    var getEditProps = (0, react_2.useButton)({ ref: editRef }).getButtonProps;
    var getDeleteProps = (0, react_2.useButton)({ ref: deleteRef }).getButtonProps;
    if (columnRecordKey !== "actions") {
        var cellView = renderCellViews(cellValue, columnRecord, setModifiedRecords).find(function (cValue) { return columnRecordKey === (cValue === null || cValue === void 0 ? void 0 : cValue.key); });
        if (cellView) {
            return cellView === null || cellView === void 0 ? void 0 : cellView.content;
        }
        else {
            return (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: cellValue }));
        }
    }
    else if (rowActions.length) {
        var rowAction = rowActions(columnRecord);
        var viewAction = rowAction.find(function (action) { return "view" === (action === null || action === void 0 ? void 0 : action.key); });
        var editAction = rowAction.find(function (action) { return "edit" === (action === null || action === void 0 ? void 0 : action.key); });
        var deleteAction = rowAction.find(function (action) { return "delete" === (action === null || action === void 0 ? void 0 : action.key); });
        return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex items-center justify-end gap-2" }, { children: [viewAction && (0, jsx_runtime_1.jsx)(eye_1.EyeFilledIcon, __assign({}, getEyesProps(), { onClick: viewAction === null || viewAction === void 0 ? void 0 : viewAction.onClick, className: "cursor-pointer text-default-400", height: 18, width: 18 })), editAction && (0, jsx_runtime_1.jsx)(edit_1.EditLinearIcon, __assign({}, getEditProps(), { onClick: editAction === null || editAction === void 0 ? void 0 : editAction.onClick, className: "cursor-pointer text-default-400", height: 18, width: 18 })), deleteAction && ((0, jsx_runtime_1.jsx)(delete_1.DeleteFilledIcon, __assign({}, getDeleteProps(), { onClick: deleteAction === null || deleteAction === void 0 ? void 0 : deleteAction.onClick, className: "cursor-pointer text-default-400", height: 18, width: 18 })))] })));
    }
};
exports.TableCellComponent = TableCellComponent;
