"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var main_1 = require("../../../../../../../reducers/slices/main");
var fields_from_schema_1 = require("../../helpers/fields_from_schema");
var data_map_preview_1 = require("./data_map_preview");
var datamap_1 = require("../../../../../../../reducers/slices/datamap/datamap");
var field_map_1 = require("./field_map");
var react_2 = require("@nextui-org/react");
var react_3 = require("@iconify/react");
var DataMapper = function (_a) {
    var schema = _a.schema, value = _a.value, onSave = _a.onSave;
    var dispatch = (0, react_redux_1.useDispatch)();
    (0, react_1.useEffect)(function () {
        if (value) {
            dispatch((0, datamap_1.setFields)(value));
        }
    }, [value]);
    (0, react_1.useEffect)(function () {
        return function () {
            dispatch((0, datamap_1.setFields)([
                {
                    localField: "",
                    targetField: "",
                    targetType: "String",
                    defaultValue: "",
                    nestedFields: [],
                    errorText: {},
                },
            ]));
        };
    }, []);
    var fields = (0, react_redux_1.useSelector)(function (state) { return state.dataMapState.fields; });
    var _b = (0, react_1.useState)(0), view = _b[0], setView = _b[1];
    var _c = (0, react_1.useState)(false), toClose = _c[0], setToClose = _c[1];
    var flatSchema = (0, fields_from_schema_1.getFlattenedSchema)(schema, "$.");
    var validateFields = function (fields, basePath) {
        if (basePath === void 0) { basePath = ""; }
        var validateField = function (field, path) {
            var errors = {
                localField: field.localField ? "" : "Local field is required",
                targetField: field.targetField ? "" : "Target field is required",
                targetType: field.targetType ? "" : "Target type is required",
            };
            dispatch((0, datamap_1.setFieldErrors)({ errors: errors, path: path }));
            return !(errors.localField || errors.targetField || errors.targetType);
        };
        var valid = true;
        fields.forEach(function (field, index) {
            var path = "".concat(basePath, ".").concat(index);
            if (!validateField(field, path)) {
                valid = false;
            }
            if (field.nestedFields) {
                var nestedPath = "".concat(path, ".nestedFields");
                if (!validateFields(field.nestedFields, nestedPath)) {
                    valid = false;
                }
            }
        });
        return valid;
    };
    // workaround to run save callback and close only after state updates are done from validation
    (0, react_1.useEffect)(function () {
        if (toClose) {
            onSave(fields);
            dispatch((0, main_1.setContentModal)(null));
        }
    }, [fields, toClose]);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4 max-h-[75vh] max-w-[80vw]" }, { children: [(0, jsx_runtime_1.jsxs)(react_2.Tabs, __assign({ "aria-label": "Field Map Tabs", selectedKey: view.toString(), onSelectionChange: function (key) { return setView(parseInt(key.toString())); } }, { children: [(0, jsx_runtime_1.jsx)(react_2.Tab, __assign({ title: "Table" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col gap-6 overflow-x-scroll py-4" }, { children: fields === null || fields === void 0 ? void 0 : fields.map(function (field, index) { return ((0, jsx_runtime_1.jsx)(field_map_1.FieldMap, { flatSchema: flatSchema, schema: schema === null || schema === void 0 ? void 0 : schema.tree, field: field, path: index.toString() }, index)); }) })) }), "0"), (0, jsx_runtime_1.jsx)(react_2.Tab, __assign({ title: "Preview" }, { children: (0, jsx_runtime_1.jsx)(data_map_preview_1.FieldMapPreview, { fields: fields }) }), "1")] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-3 items-center" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "w-[100px]" }, { children: (0, jsx_runtime_1.jsx)(react_2.Button, __assign({ color: "primary", size: "md", onPress: function () {
                                if (validateFields(fields)) {
                                    setToClose(true);
                                }
                            } }, { children: "Save" })) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex justify-center" }, { children: (0, jsx_runtime_1.jsx)(react_2.Button, __assign({ isIconOnly: true, variant: "light", size: "lg", color: "secondary", onPress: function () { return dispatch((0, datamap_1.addField)({ field: null, path: "" })); } }, { children: (0, jsx_runtime_1.jsx)(react_3.Icon, { icon: "solar:add-circle-outline", width: "24", height: "24" }) })) }))] }))] })));
};
exports.default = DataMapper;
