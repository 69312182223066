"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextControl = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
function TextControl(props) {
    var value = props.value, _a = props.className, className = _a === void 0 ? "" : _a, _b = props.size, size = _b === void 0 ? "md" : _b, _c = props.bold, bold = _c === void 0 ? false : _c;
    var sizeMap = {
        xs: "text-xs",
        sm: "text-sm",
        md: "text-md",
        lg: "text-lg",
        xl: "text-xl",
        "2xl": "text-2xl",
        "3xl": "text-3xl",
        "4xl": "text-4xl",
        "5xl": "text-5xl",
        "6xl": "text-6xl",
        "7xl": "text-7xl",
        "8xl": "text-8xl",
        "9xl": "text-9xl",
    };
    return (0, jsx_runtime_1.jsx)("div", __assign({ className: "".concat(sizeMap[size], " ").concat(bold ? "font-bold" : "", " ").concat(className) }, { children: value }));
}
exports.TextControl = TextControl;
