"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.excludeSortColumns = exports.initialProductValues = exports.CHANNELS = exports.STATUS = exports.INITIAL_VISIBLE_COLUMNS = exports.statusColorMap = exports.statusOptions = void 0;
var danger_circle_1 = require("../../../../../components/icons/danger-circle");
var default_circle_1 = require("../../../../../components/icons/default-circle");
var success_circle_1 = require("../../../../../components/icons/success-circle");
var warning_circle_1 = require("../../../../../components/icons/warning-circle");
exports.statusOptions = [
    { label: "Active", value: "ACTIVE" },
    { label: "Completed", value: "COMPLETED" },
    { label: "Fulfilled", value: "FULFILLED" },
    { label: "Processed", value: "PROCESSED" },
    { label: "Ordered", value: "ORDERED" },
    { label: "Ready", value: "READY" },
    { label: "Arrived", value: "ARRIVED" },
    { label: "Delivered", value: "DELIVERED" },
    { label: "Online", value: "ONLINE" },
    { label: "Available", value: "AVAILABLE" },
    { label: "Complete", value: "COMPLETE" },
    { label: "Success", value: "SUCCESS" },
    { label: "Accepted", value: "ACCEPTED" },
];
exports.statusColorMap = {
    ACTIVE: default_circle_1.DefaultCircleSvg,
    COMPLETED: default_circle_1.DefaultCircleSvg,
    FULFILLED: success_circle_1.SuccessCircleSvg,
    PROCESSED: warning_circle_1.WarningCircleSvg,
    ORDERED: success_circle_1.SuccessCircleSvg,
    READY: default_circle_1.DefaultCircleSvg,
    ARRIVED: success_circle_1.SuccessCircleSvg,
    DELIVERED: success_circle_1.SuccessCircleSvg,
    ONLINE: success_circle_1.SuccessCircleSvg,
    AVAILABLE: default_circle_1.DefaultCircleSvg,
    COMPLETE: danger_circle_1.DangerCircleSvg,
    SUCCESS: success_circle_1.SuccessCircleSvg,
    ACCEPTED: success_circle_1.SuccessCircleSvg,
};
exports.INITIAL_VISIBLE_COLUMNS = [
    "status",
    "channels",
    "on_sale_price",
    "on_sale",
    "price",
    "category",
    "title",
    "pid",
    "sku",
    "image_url",
    "actions"
];
exports.STATUS = [
    { label: "All", value: "ALL" },
    { label: "Active", value: "ACTIVE" },
    { label: "Available", value: "AVAILABLE" },
    { label: "Ready", value: "READY" },
    { label: "Online", value: "ONLINE" },
    { label: "Draft", value: "DRAFT" },
];
exports.CHANNELS = [
    { label: "Arch Store", value: "ARCH_STORE" },
    { label: "Shopify", value: "SHOPIFY" },
    { label: "Google", value: "GOOGLE" },
    { label: "Facebook", value: "FACEBOOK" },
    { label: "Simphony", value: "SIMPHONY" },
    { label: "STS", value: "STS" },
    { label: "Sales Layer", value: "SALES_LAYER" },
];
exports.initialProductValues = {
    _id: "",
    image_url: "",
    image_list: [],
    title: "",
    sub_title: "",
    description: "",
    brand: "",
    manufacturer: "",
    sku: "",
    pid: "",
    quantity_type: "",
    quantity: "",
    cost_price: "0.00",
    price: "0.00",
    recommended: true,
    on_promotion: false,
    vatable: true,
    track_quantity: true,
    notify_when_stock_low: true,
    continue_selling_when_out_of_stock: false,
    promotion_price: "0.00",
    variations: [],
    category_id: "",
    sub_category_id: "",
    status: "",
    type_id: "",
    barcode: "",
    notes: "",
    extra_fields: [],
    add_ons: [],
    tags: [],
    channels: [],
    availability: "ONLINE",
    condiments: [],
};
exports.excludeSortColumns = ["on_sale_price", "on_sale", "image_url", "channels", "category"];
