"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var upload_file_1 = require("../../../../../../api/calls/media/upload_file");
var update_product_variances_1 = require("../../../../../../api/calls/product/variances/update_product_variances");
var main_1 = require("../../../../../../reducers/slices/main");
var STATUSES = [
    { label: "Available", value: "AVAILABLE" },
    { label: "Out of stock", value: "OUT-OF-STOCK" },
    { label: "Draft", value: "DRAFT" },
];
var EditVariant = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var dispatch = (0, react_redux_1.useDispatch)();
    var selectedItem = props.selectedItem, onTaskComplete = props.onTaskComplete, onCreate = props.onCreate;
    var _k = (0, react_1.useState)(selectedItem), selectedItemData = _k[0], setSelectedItemData = _k[1];
    var _l = (0, react_1.useState)(false), isVarianceImageLoading = _l[0], setIsVarianceImageLoading = _l[1];
    var _m = (0, react_1.useState)(false), showFileInputControl = _m[0], setShowFileInputControl = _m[1];
    var _o = (0, react_1.useState)(doshx_controls_web_1.Utilities.randomAlphanumeric(10)), _id = _o[0], setId = _o[1];
    (0, react_1.useEffect)(function () {
        setSelectedItemData(selectedItem);
    }, [selectedItem]);
    var onChange = function (key, value) {
        var _a;
        setSelectedItemData(__assign(__assign({}, selectedItemData), (_a = {}, _a[key] = value, _a)));
    };
    var uploadImage = function (file, file_name) {
        setIsVarianceImageLoading(true);
        (0, upload_file_1.upload_file)({
            file: file,
            file_name: file_name,
            description: "Letstrade product variations images",
            type: "IMAGE",
            category: "PRODUCTS-VARIATIONS",
        }).then(function (res) {
            if (res.success) {
                setIsVarianceImageLoading(false);
                onChange("image_url", res.content.location);
            }
        });
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { size: "large", label: (selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.ref) ? "Edit Variant" : "Create a Variant", bold: true }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-2 gap-4 bg-white" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Variant Type", type: "text", size: "small", borderColor: "border-borders", value: (_a = selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.type) !== null && _a !== void 0 ? _a : "", errorText: "", onChange: function (v) {
                            onChange("type", v === null || v === void 0 ? void 0 : v.toUpperCase());
                        } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Title", type: "text", size: "small", borderColor: "border-borders", value: (_b = selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.title) !== null && _b !== void 0 ? _b : "", errorText: "", onChange: function (v) {
                            if (!(selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.ref)) {
                                setSelectedItemData(__assign(__assign({}, selectedItemData), { _id: doshx_controls_web_1.Utilities.randomAlphanumeric(10) }));
                            }
                            onChange("title", doshx_controls_web_1.Utilities.capitalizeFirstLetter(v));
                        } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "SKU", type: "text", size: "small", borderColor: "border-borders", value: (_c = selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.sku) !== null && _c !== void 0 ? _c : "", errorText: "", onChange: function (v) {
                            onChange("sku", v);
                        } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "PID", type: "text", size: "small", borderColor: "border-borders", value: (_d = selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.pid) !== null && _d !== void 0 ? _d : "", errorText: "", onChange: function (v) {
                            onChange("pid", v);
                        } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Price", type: "text", size: "small", borderColor: "border-borders", value: (_e = selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.price) !== null && _e !== void 0 ? _e : "", errorText: "", onChange: function (v) {
                            onChange("price", v);
                        } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DropdownControl, { label: "Status", borderColor: "border-borders", required: true, options: STATUSES, iconColor: "text-primary", size: "small", value: (_f = selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.status) !== null && _f !== void 0 ? _f : "", onChange: function (v) {
                            onChange("status", v);
                        }, errorText: "" }), (0, jsx_runtime_1.jsxs)("div", { children: [showFileInputControl && ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "" }, { children: [isVarianceImageLoading && (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CircularLoaderControl, { size: "xsmall" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.FileInputControl, { previewSide: "right", startIcon: pro_solid_svg_icons_1.faFileArrowUp, colors: { iconColor: "text-blue-600", borderColor: "border-borders" }, size: "small", previewFile: true, label: "" ? "Update Image" : "Upload Image", errorText: "", onChange: function (f) {
                                            uploadImage(f, f.name);
                                        } })] }))), !showFileInputControl && ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex items-center relative border border-solid border-borders p-0 rounded-lg " }, { children: [(0, jsx_runtime_1.jsx)("img", { src: selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.image_url, alt: "Image", className: "w-10 h-10 transition-all duration-300 rounded-lg cursor-pointer" }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "absolute right-2 bg-gray-600 rounded-full text-white" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.IconButtonControl, { icon: pro_solid_svg_icons_1.faPencil, size: "2xsmall", backgroundColor: "bg-blue-700", borderColor: "border-transparent", iconColor: "text-white", shape: "circle", onClick: function () {
                                                setShowFileInputControl(true);
                                            } }) }))] }))), (0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { disabled: false, label: "Is Default", value: (_g = selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.is_default) !== null && _g !== void 0 ? _g : false, onChange: function (v) {
                                        onChange("is_default", v);
                                    }, textColor: "text-blue-500", size: "small" }) })] }), (0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Promotion Price", type: "number", size: "small", borderColor: "border-borders", value: (_h = selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.promotion_price) !== null && _h !== void 0 ? _h : "", errorText: "", onChange: function (v) {
                                    onChange("promotion_price", v);
                                } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { label: "On Promotion", value: (_j = selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.on_promotion) !== null && _j !== void 0 ? _j : false, onChange: function (v) {
                                    onChange("on_promotion", v);
                                }, textColor: "text-blue-500", size: "small" })] })] })), (selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.type) && (selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.title) && ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.BoxControl, __assign({ className: "flex flex-col p-1" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.BoxControl, __assign({ backgroundColor: "bg-white", variant: "filled" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row gap-8 items-center", draggable: "true", onDragStart: function (e) {
                            e.dataTransfer.setData("drag_data", JSON.stringify({
                                _id: _id,
                                title: !(selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.ref) && (selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.title),
                                data: selectedItemData,
                            }));
                        }, onDragEnd: function () {
                            setId(doshx_controls_web_1.Utilities.randomAlphanumeric(10));
                        } }, { children: [(0, jsx_runtime_1.jsx)("span", __assign({ className: "text-3xl" }, { children: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faGripDotsVertical }) })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex-1" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-2" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { size: "large", label: "ID", bold: true }) })), (0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { size: "large", label: "Title", bold: true }) })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-2" }, { children: [(0, jsx_runtime_1.jsx)("div", { children: _id }), (0, jsx_runtime_1.jsx)("div", { children: selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.title })] }))] })), (0, jsx_runtime_1.jsx)("span", __assign({ className: "text-3xl" }, { children: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faGripDotsVertical }) }))] })) })) }))), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-row justify-end" }, { children: (selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.ref) ? ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row items-center gap-2 p-1" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LinkButtonControl, { icon: pro_solid_svg_icons_1.faRefresh, label: "Reset", size: "medium", onClick: function () {
                                setSelectedItemData({});
                            } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { iconColor: "text-white", label: "Update", size: "small", startIcon: pro_solid_svg_icons_1.faPen, enabled: true, backgroundColor: "bg-primary", textColor: "text-white", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                var oldRoot, updateItem, modified, request, response;
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            oldRoot = selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.root;
                                            updateItem = function (node, ref, modifiedData) {
                                                if ((node === null || node === void 0 ? void 0 : node.ref) === ref) {
                                                    node = modifiedData;
                                                    return node;
                                                }
                                                if (node === null || node === void 0 ? void 0 : node.variations) {
                                                    node.variations = node.variations.map(function (item) { return updateItem(item, ref, modifiedData); });
                                                }
                                                return node;
                                            };
                                            modified = updateItem(oldRoot, selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.ref, selectedItemData);
                                            (_a = modified === null || modified === void 0 ? void 0 : modified.variations) === null || _a === void 0 ? void 0 : _a.forEach(function (item) {
                                                if (item === null || item === void 0 ? void 0 : item.root) {
                                                    delete item.root;
                                                }
                                            });
                                            request = {
                                                variance_id: modified === null || modified === void 0 ? void 0 : modified._id,
                                                node: {
                                                    client_id: modified === null || modified === void 0 ? void 0 : modified.client_id,
                                                    product_id: modified === null || modified === void 0 ? void 0 : modified.product_id,
                                                    status: modified === null || modified === void 0 ? void 0 : modified.status,
                                                    type: modified === null || modified === void 0 ? void 0 : modified.type,
                                                    title: modified === null || modified === void 0 ? void 0 : modified.title,
                                                    is_default: modified === null || modified === void 0 ? void 0 : modified.is_default,
                                                    price: modified === null || modified === void 0 ? void 0 : modified.price,
                                                    sku: modified === null || modified === void 0 ? void 0 : modified.sku,
                                                    pid: modified === null || modified === void 0 ? void 0 : modified.pid,
                                                    on_promotion: modified === null || modified === void 0 ? void 0 : modified.on_promotion,
                                                    promotion_price: modified === null || modified === void 0 ? void 0 : modified.promotion_price,
                                                    image_url: modified === null || modified === void 0 ? void 0 : modified.image_url,
                                                    variations: modified === null || modified === void 0 ? void 0 : modified.variations,
                                                },
                                            };
                                            return [4 /*yield*/, (0, update_product_variances_1.update_product_variances)(request)];
                                        case 1:
                                            response = _b.sent();
                                            if (response.success) {
                                                onTaskComplete();
                                                dispatch((0, main_1.setMessageModal)({ title: "Success...", messages: [response.message] }));
                                            }
                                            else {
                                                onTaskComplete();
                                                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                                            }
                                            return [2 /*return*/];
                                    }
                                });
                            }); } })] }))) : null }))] })));
};
exports.default = EditVariant;
