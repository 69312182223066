"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setTablePagination = exports.setIsLoading = exports.setSelectedProductAttribute = exports.setProductAttributes = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var pages_1 = require("../../../../constants/pages");
var initialState = {
    productAttributes: [],
    selectedProductAttribute: null,
    pagination: pages_1.initialPaginationValues,
    loading: false,
    error: null,
};
var productAttributeSlice = (0, toolkit_1.createSlice)({
    name: 'productAttribute',
    initialState: initialState,
    reducers: {
        setProductAttributes: function (state, action) {
            state.productAttributes = action.payload;
        },
        setSelectedProductAttribute: function (state, action) {
            state.selectedProductAttribute = action.payload;
        },
        setIsLoading: function (state, action) {
            state.loading = action.payload;
        },
        setTablePagination: function (state, action) {
            state.pagination = action.payload;
        },
    },
});
exports.setProductAttributes = (_a = productAttributeSlice.actions, _a.setProductAttributes), exports.setSelectedProductAttribute = _a.setSelectedProductAttribute, exports.setIsLoading = _a.setIsLoading, exports.setTablePagination = _a.setTablePagination;
exports.default = productAttributeSlice.reducer;
