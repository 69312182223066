"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.columns = exports.INITIAL_VISIBLE_COLUMNS = void 0;
exports.INITIAL_VISIBLE_COLUMNS = [
    "name",
    "description",
    "type",
    "actions"
];
exports.columns = [
    { name: "Name", uid: "name" },
    { name: "Description", uid: "description" },
    { name: "Type", uid: "type" },
    { name: "Actions", uid: "actions" }
];
