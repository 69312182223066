"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var react_2 = require("@nextui-org/react");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var upload_file_1 = require("../../../../../../../../api/calls/media/upload_file");
var update_product_variances_1 = require("../../../../../../../../api/calls/product/variances/update_product_variances");
var main_1 = require("../../../../../../../../reducers/slices/main");
var STATUSES = [
    { label: "Available", value: "AVAILABLE" },
    { label: "Out of stock", value: "OUT-OF-STOCK" },
    { label: "Draft", value: "DRAFT" },
];
var EditVariantFormComponent = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var dispatch = (0, react_redux_1.useDispatch)();
    var selectedItem = props.selectedItem, onTaskComplete = props.onTaskComplete, onCreate = props.onCreate;
    var _l = (0, react_1.useState)(selectedItem), selectedItemData = _l[0], setSelectedItemData = _l[1];
    var _m = (0, react_1.useState)(false), isVarianceImageLoading = _m[0], setIsVarianceImageLoading = _m[1];
    var _o = (0, react_1.useState)(false), showFileInputControl = _o[0], setShowFileInputControl = _o[1];
    var _p = (0, react_1.useState)(doshx_controls_web_1.Utilities.randomAlphanumeric(10)), _id = _p[0], setId = _p[1];
    var STATUSES = [
        { label: "Active", value: "ACTIVE" },
        { label: "Inactive", value: "INACTIVE" },
    ];
    var onChange = function (key, value) {
        var _a;
        setSelectedItemData(__assign(__assign({}, selectedItemData), (_a = {}, _a[key] = value, _a)));
    };
    var uploadImage = function (file, fileName) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setIsVarianceImageLoading(true);
            (0, upload_file_1.upload_file)({
                file: file,
                file_name: fileName,
                description: "Letstrade product variations images",
                type: "IMAGE",
                category: "PRODUCTS-VARIATIONS",
            }).then(function (res) {
                if (res.success) {
                    setIsVarianceImageLoading(false);
                    onChange("image_url", res.content.location);
                }
            });
            return [2 /*return*/];
        });
    }); };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsx)("h2", __assign({ className: "text-md font-bold" }, { children: (selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.ref) ? "Edit Variant" : "Create a Variant" })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-2 gap-4 bg-white" }, { children: [(0, jsx_runtime_1.jsx)(react_2.Input, { label: "Variant Type", size: "sm", value: (_a = selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.type) !== null && _a !== void 0 ? _a : "", onChange: function (e) {
                            onChange("type", e.target.value.toUpperCase());
                        }, classNames: {
                            input: "border-borders",
                            inputWrapper: "border-borders",
                        } }), (0, jsx_runtime_1.jsx)(react_2.Input, { label: "Title", size: "sm", value: (_b = selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.title) !== null && _b !== void 0 ? _b : "", onChange: function (e) {
                            if (!(selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.ref)) {
                                setSelectedItemData(__assign(__assign({}, selectedItemData), { _id: doshx_controls_web_1.Utilities.randomAlphanumeric(10) }));
                            }
                            onChange("title", doshx_controls_web_1.Utilities.capitalizeFirstLetter(e.target.value));
                        }, classNames: {
                            input: "border-borders",
                            inputWrapper: "border-borders",
                        } }), (0, jsx_runtime_1.jsx)(react_2.Input, { label: "SKU", size: "sm", value: (_c = selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.sku) !== null && _c !== void 0 ? _c : "", onChange: function (e) { return onChange("sku", e.target.value); }, classNames: {
                            input: "border-borders",
                            inputWrapper: "border-borders",
                        } }), (0, jsx_runtime_1.jsx)(react_2.Input, { label: "PID", size: "sm", value: (_d = selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.pid) !== null && _d !== void 0 ? _d : "", onChange: function (e) { return onChange("pid", e.target.value); }, classNames: {
                            input: "border-borders",
                            inputWrapper: "border-borders",
                        } }), (0, jsx_runtime_1.jsx)(react_2.Input, { label: "Price", type: "text", size: "sm", value: (_e = selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.price) !== null && _e !== void 0 ? _e : "", onChange: function (e) { return onChange("price", e.target.value); }, classNames: {
                            input: "border-borders",
                            inputWrapper: "border-borders",
                        } }), (0, jsx_runtime_1.jsxs)(react_2.Dropdown, { children: [(0, jsx_runtime_1.jsx)(react_2.DropdownTrigger, { children: (0, jsx_runtime_1.jsx)(react_2.Input, { label: "Status", size: "sm", value: (_f = selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.status) !== null && _f !== void 0 ? _f : "", readOnly: true, classNames: {
                                        input: "border-borders",
                                        inputWrapper: "border-borders",
                                    } }) }), (0, jsx_runtime_1.jsx)(react_2.DropdownMenu, __assign({ onAction: function (key) { return onChange("status", key); }, selectedKeys: (selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.status) ? [selectedItemData.status] : [] }, { children: STATUSES.map(function (status) { return ((0, jsx_runtime_1.jsx)(react_2.DropdownItem, { children: status.label }, status.value)); }) }))] }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "space-y-2" }, { children: [showFileInputControl ? ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "relative" }, { children: [isVarianceImageLoading && ((0, jsx_runtime_1.jsx)("div", { className: "absolute inset-0 flex items-center justify-center bg-white/50" })), (0, jsx_runtime_1.jsx)(react_2.Input, { type: "file", size: "sm", label: "Upload Image", onChange: function (e) {
                                            var _a;
                                            var file = (_a = e.target.files) === null || _a === void 0 ? void 0 : _a[0];
                                            if (file)
                                                uploadImage(file, file.name);
                                        }, endContent: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faUpload }), classNames: {
                                            input: "border-borders",
                                            inputWrapper: "border-borders",
                                        } })] }))) : ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "relative border border-borders rounded-lg p-1" }, { children: [(0, jsx_runtime_1.jsx)("img", { src: (_g = selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.image_url) !== null && _g !== void 0 ? _g : "", alt: "Variant", className: "w-10 h-10 rounded-lg cursor-pointer" }), (0, jsx_runtime_1.jsx)(react_2.Button, __assign({ isIconOnly: true, size: "sm", className: "absolute right-2 top-1/2 -translate-y-1/2 bg-white", onClick: function () { return setShowFileInputControl(true); } }, { children: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faPencil }) }))] }))), (0, jsx_runtime_1.jsx)(react_2.Checkbox, __assign({ isSelected: (_h = selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.is_default) !== null && _h !== void 0 ? _h : false, onValueChange: function (checked) { return onChange("is_default", checked); }, size: "sm", className: "text-blue-500" }, { children: "Is Default" }))] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "space-y-2" }, { children: [(0, jsx_runtime_1.jsx)(react_2.Input, { label: "Promotion Price", type: "number", size: "sm", value: (_j = selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.promotion_price) !== null && _j !== void 0 ? _j : "", onChange: function (e) { return onChange("promotion_price", e.target.value); }, classNames: {
                                    input: "border-borders",
                                    inputWrapper: "border-borders",
                                } }), (0, jsx_runtime_1.jsx)(react_2.Checkbox, __assign({ isSelected: (_k = selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.on_promotion) !== null && _k !== void 0 ? _k : false, onValueChange: function (checked) { return onChange("on_promotion", checked); }, size: "sm", className: "text-blue-500" }, { children: "On Promotion" }))] }))] })), (selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.type) && (selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.title) && ((0, jsx_runtime_1.jsx)(react_2.Card, __assign({ className: "p-4" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row gap-8 items-center", draggable: "true", onDragStart: function (e) {
                        e.dataTransfer.setData("drag_data", JSON.stringify({
                            _id: _id,
                            title: !(selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.ref) && (selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.title),
                            data: selectedItemData,
                        }));
                    }, onDragEnd: function () {
                        setId(doshx_controls_web_1.Utilities.randomAlphanumeric(10));
                    } }, { children: [(0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faGrip }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex-1" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-2" }, { children: [(0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)("span", __assign({ className: "text-lg font-bold" }, { children: "ID" })) }), (0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)("span", __assign({ className: "text-lg font-bold" }, { children: "Title" })) })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-2" }, { children: [(0, jsx_runtime_1.jsx)("div", { children: _id }), (0, jsx_runtime_1.jsx)("div", { children: selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.title })] }))] })), (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faGrip })] })) }))), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-row justify-end" }, { children: (selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.ref) && ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row items-center gap-2 p-1" }, { children: [(0, jsx_runtime_1.jsx)(react_2.Button, __assign({ variant: "light", startContent: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faRefresh }), onPress: function () { return setSelectedItemData({}); } }, { children: "Reset" })), (0, jsx_runtime_1.jsx)(react_2.Button, __assign({ color: "primary", startContent: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faPencil }), onPress: function () { return __awaiter(void 0, void 0, void 0, function () {
                                var oldRoot, updateItem, modified, request, response;
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            oldRoot = selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.root;
                                            updateItem = function (node, ref, modifiedData) {
                                                if ((node === null || node === void 0 ? void 0 : node.ref) === ref) {
                                                    node = modifiedData;
                                                    return node;
                                                }
                                                if (node === null || node === void 0 ? void 0 : node.variations) {
                                                    node.variations = node.variations.map(function (item) { return updateItem(item, ref, modifiedData); });
                                                }
                                                return node;
                                            };
                                            modified = updateItem(oldRoot, selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.ref, selectedItemData);
                                            (_a = modified === null || modified === void 0 ? void 0 : modified.variations) === null || _a === void 0 ? void 0 : _a.forEach(function (item) {
                                                if (item === null || item === void 0 ? void 0 : item.root) {
                                                    delete item.root;
                                                }
                                            });
                                            request = {
                                                variance_id: modified === null || modified === void 0 ? void 0 : modified._id,
                                                node: {
                                                    client_id: modified === null || modified === void 0 ? void 0 : modified.client_id,
                                                    product_id: modified === null || modified === void 0 ? void 0 : modified.product_id,
                                                    status: modified === null || modified === void 0 ? void 0 : modified.status,
                                                    type: modified === null || modified === void 0 ? void 0 : modified.type,
                                                    title: modified === null || modified === void 0 ? void 0 : modified.title,
                                                    is_default: modified === null || modified === void 0 ? void 0 : modified.is_default,
                                                    price: modified === null || modified === void 0 ? void 0 : modified.price,
                                                    sku: modified === null || modified === void 0 ? void 0 : modified.sku,
                                                    pid: modified === null || modified === void 0 ? void 0 : modified.pid,
                                                    on_promotion: modified === null || modified === void 0 ? void 0 : modified.on_promotion,
                                                    promotion_price: modified === null || modified === void 0 ? void 0 : modified.promotion_price,
                                                    image_url: modified === null || modified === void 0 ? void 0 : modified.image_url,
                                                    variations: modified === null || modified === void 0 ? void 0 : modified.variations,
                                                },
                                            };
                                            return [4 /*yield*/, (0, update_product_variances_1.update_product_variances)(request)];
                                        case 1:
                                            response = _b.sent();
                                            if (response.success) {
                                                onTaskComplete();
                                                dispatch((0, main_1.setMessageModal)({
                                                    title: "Success...",
                                                    messages: [response.message],
                                                }));
                                            }
                                            else {
                                                onTaskComplete();
                                                dispatch((0, main_1.setMessageModal)({
                                                    title: "Something went wrong!",
                                                    messages: [response.message],
                                                }));
                                            }
                                            return [2 /*return*/];
                                    }
                                });
                            }); } }, { children: "Update" }))] }))) }))] })));
};
exports.default = EditVariantFormComponent;
