"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangeBranchModal = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_2 = require("@nextui-org/react");
var react_hook_form_1 = require("react-hook-form");
var useChangeBranchModal_1 = require("./hooks/useChangeBranchModal");
function ChangeBranchModal(_a) {
    var isOpen = _a.isOpen, onOpenChanged = _a.onOpenChanged;
    var _b = (0, useChangeBranchModal_1.useChangeBranchModalState)(), branchOptions = _b.branchOptions, control = _b.control, onSelectedOrderBranchChanged = _b.onSelectedOrderBranchChanged;
    var handleOpenChanged = (0, react_1.useCallback)(function () {
        onOpenChanged();
    }, [onOpenChanged]);
    var handleSubmit = (0, react_1.useCallback)(function (onClose) { return function () {
        onSelectedOrderBranchChanged().then(function () {
            onClose();
        });
    }; }, [onSelectedOrderBranchChanged]);
    return ((0, jsx_runtime_1.jsx)(react_2.Modal, __assign({ backdrop: "opaque", isOpen: isOpen, onOpenChange: handleOpenChanged }, { children: (0, jsx_runtime_1.jsx)(react_2.ModalContent, { children: function (onClose) { return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(react_2.ModalHeader, __assign({ className: "flex flex-col gap-1" }, { children: "Change Branch" })), (0, jsx_runtime_1.jsx)(react_2.ModalBody, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-96 h-32" }, { children: (0, jsx_runtime_1.jsx)(react_hook_form_1.Controller, { control: control, name: "branch", render: function (_a) {
                                    var _b = _a.field, onChange = _b.onChange, value = _b.value;
                                    return ((0, jsx_runtime_1.jsx)(react_2.Select, __assign({ className: "col-span-12 md:col-span-6", classNames: {
                                            label: "text-small font-medium text-default-700 group-data-[filled=true]:text-default-700",
                                        }, items: branchOptions, label: "Branch", labelPlacement: "outside", name: "company-type", placeholder: "Branch", onChange: onChange }, { children: function (option) { return (0, jsx_runtime_1.jsx)(react_2.SelectItem, { children: option.display_name }, option._id); } })));
                                } }) })) }), (0, jsx_runtime_1.jsxs)(react_2.ModalFooter, { children: [(0, jsx_runtime_1.jsx)(react_2.Button, __assign({ color: "default", variant: "light", onPress: onClose }, { children: "Cancel" })), (0, jsx_runtime_1.jsx)(react_2.Button, __assign({ color: "primary", onPress: handleSubmit(onClose) }, { children: "Submit" }))] })] })); } }) })));
}
exports.ChangeBranchModal = ChangeBranchModal;
