"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrdersPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_router_dom_1 = require("react-router-dom");
var routes_1 = require("../../../../constants/routes");
var order_1 = require("./order");
var orders_table_1 = require("./pages/ordersTable/orders_table");
function OrdersPage() {
    return ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsxs)(react_router_dom_1.Routes, { children: [(0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: "/*", element: (0, jsx_runtime_1.jsx)(orders_table_1.OrdersTablePage, {}) }), (0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: "".concat(routes_1.ORDER_ROUTE, "/:_id"), element: (0, jsx_runtime_1.jsx)(order_1.OrderPage, {}) })] }) }));
}
exports.OrdersPage = OrdersPage;
