"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddOnsTableColumns = exports.columns = exports.INITIAL_ADDON_VISIBLE_COLUMNS = exports.buildVariationTableColumns = exports.productAddOnsTableColumns = exports.PROMOTION_DURATION = exports.PRODUCT_AVAILABILITY = void 0;
exports.PRODUCT_AVAILABILITY = [
    { label: "Offline Products", value: "OFFLINE" },
    { label: "Online Products", value: "ONLINE" },
];
exports.PROMOTION_DURATION = [
    { label: "Unlimited", value: "UNLIMITED" },
    { label: "Fixed", value: "FIXED" },
];
exports.productAddOnsTableColumns = [
    {
        displayKey: "title",
        label: "Title",
    },
    {
        displayKey: "sku",
        label: "SKU",
    },
    {
        displayKey: "price",
        label: "Price",
    },
    {
        displayKey: "required",
        label: "Required",
    },
    {
        displayKey: "quantity",
        label: "Amount Per Variant",
    },
];
exports.buildVariationTableColumns = [
    {
        uid: "title",
        name: "Title",
    },
    {
        uid: "sku",
        name: "SKU",
    },
    {
        uid: "pid",
        name: "PID",
    },
    {
        uid: "price",
        name: "Price",
    },
    {
        uid: "status",
        name: "Status",
    },
    {
        uid: "is_default",
        name: "Default",
    },
];
exports.INITIAL_ADDON_VISIBLE_COLUMNS = [
    "title",
    "sku",
    "price",
    "required",
    "quantity",
    "action",
];
exports.columns = [
    { name: "Title", uid: "title" },
    { name: "SKU", uid: "sku" },
    { name: "Price", uid: "price" },
    { name: "Required", uid: "required" },
    { name: "Amount Per Variant", uid: "quantity" },
    { name: "Actions", uid: "actions" }
];
exports.AddOnsTableColumns = [
    {
        displayKey: "loyalty_number",
        label: "Image",
    },
    {
        displayKey: "loyalty_number",
        label: "Price",
    },
    {
        displayKey: "linked",
        label: "Promotional Price",
    },
    {
        displayKey: "member_date",
        label: "Stock Level",
    },
];
