"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRowActions = void 0;
var react_1 = require("react");
function useRowActions() {
    var rowActions = (0, react_1.useCallback)(function (cellValue) { return []; }, []);
    return (0, react_1.useMemo)(function () { return ({
        rowActions: rowActions,
    }); }, [rowActions]);
}
exports.useRowActions = useRowActions;
