"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var update_product_variances_1 = require("../../../../../../api/calls/product/variances/update_product_variances");
var pages_1 = require("../../../../../../constants/pages");
var useSearchDebounce_1 = require("../../../../../../hooks/useSearchDebounce");
var main_1 = require("../../../../../../reducers/slices/main");
var get_products_shopify_thunk_1 = require("../../../../../../reducers/slices/products/thunks/get_products_shopify_thunk");
var variantAddOnsTableColumns = [
    {
        displayKey: "title",
        label: "Title",
    },
    {
        displayKey: "sku",
        label: "SKU",
    },
    {
        displayKey: "price",
        label: "Price",
    },
    {
        displayKey: "required",
        label: "Required",
    },
    {
        displayKey: "amount_per_variant",
        label: "Amount Per Variant",
    },
];
var PimProductVariationAddOns = function (props) {
    var _a, _b;
    var selectedItem = props.selectedItem, onTaskComplete = props.onTaskComplete;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _c = (0, react_1.useState)(selectedItem), selectedItemData = _c[0], setSelectedItemData = _c[1];
    var _d = (0, useSearchDebounce_1.useSearchDebounce)(), searchAddOnProducts = _d[0], setSearchAddOnProducts = _d[1];
    var _e = (0, react_1.useState)(""), addOn = _e[0], setAddOn = _e[1];
    var _f = (0, react_1.useState)(false), showSaveChangesLabel = _f[0], setShowSaveChangesLabel = _f[1];
    var _g = (0, react_1.useState)(1), currentPage = _g[0], setCurrentPage = _g[1];
    var _h = (0, react_1.useState)(10), rowsPerPage = _h[0], setRowsPerPage = _h[1];
    var _j = (0, react_redux_1.useSelector)(function (state) { return state.productState; }), productAddOnsPagination = _j.productAddOnsPagination, productAddOns = _j.productAddOns;
    var client_token = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).client_token;
    (0, react_1.useEffect)(function () {
        setSelectedItemData(selectedItem);
    }, [selectedItem]);
    (0, react_1.useEffect)(function () {
        if (client_token) {
            dispatch((0, get_products_shopify_thunk_1.get_products_thunk)(__assign(__assign({}, productAddOnsPagination), { is_add_ons_request: true })));
        }
    }, [client_token]);
    (0, react_1.useEffect)(function () {
        onSearch(searchAddOnProducts);
    }, [searchAddOnProducts]);
    var getProducts = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, get_products_shopify_thunk_1.get_products_thunk)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var onSearch = function (search_string) {
        getProducts(__assign(__assign(__assign({}, pages_1.initialPaginationValues), (search_string && { search_string: search_string })), { is_add_ons_request: true }));
    };
    var onPageChange = function (page) {
        setCurrentPage(page);
        if (searchAddOnProducts === "") {
            return getProducts(__assign(__assign({ is_add_ons_request: true }, productAddOnsPagination), { page: page }));
        }
        else {
            return getProducts(__assign(__assign({ is_add_ons_request: true }, productAddOnsPagination), { page: page, search_string: searchAddOnProducts }));
        }
    };
    return ((0, jsx_runtime_1.jsxs)(doshx_controls_web_1.BoxControl, __assign({ backgroundColor: "bg-white", variant: "filled", className: "" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex gap-4" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-80" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DropdownSearchControl, { renderDisplay: function (v) { return v.label; }, label: "Find product to place as an add on", required: true, options: (_a = productAddOns === null || productAddOns === void 0 ? void 0 : productAddOns.map(function (product, i) {
                                return { label: product.title, value: product === null || product === void 0 ? void 0 : product._id };
                            })) !== null && _a !== void 0 ? _a : [], colors: {
                                labelColor: "text-black",
                                textColor: "text-black",
                                borderColor: "border-borders",
                                iconColor: "text-black",
                            }, size: "medium", value: addOn, errorText: "", onChange: function (v) {
                                setAddOn(v);
                                var product = productAddOns === null || productAddOns === void 0 ? void 0 : productAddOns.find(function (selectedProduct) { return (selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct._id) === (v === null || v === void 0 ? void 0 : v.value); });
                                var arr = (selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.add_ons) ? __spreadArray([], selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.add_ons, true) : [];
                                arr.push(__assign(__assign({}, product), { amount_per_variant: "", is_required: false }));
                                setSelectedItemData(__assign(__assign({}, selectedItemData), { add_ons: __spreadArray([], arr, true) }));
                                setShowSaveChangesLabel(true);
                            }, onSearchChanged: function (search_string) {
                                setSearchAddOnProducts(search_string);
                            }, pagination: {
                                currentPage: currentPage,
                                recordsPerPage: rowsPerPage,
                                totalRecords: productAddOnsPagination === null || productAddOnsPagination === void 0 ? void 0 : productAddOnsPagination.countTotal,
                                position: "center",
                                onClick: function (page) {
                                    onPageChange(page);
                                },
                            } }) })) })) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "grid grid-cols-10 gap-2 mt-4 bg-black items-center p-2 rounded" }, { children: variantAddOnsTableColumns === null || variantAddOnsTableColumns === void 0 ? void 0 : variantAddOnsTableColumns.map(function (_a, index) {
                    var label = _a.label, displayKey = _a.displayKey;
                    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "col-span-2 flex items-center" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: label, className: "overflow-anywhere text-white", size: "small", textColor: "text-black", bold: true }), displayKey === "required" && ((0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-row items-center h-4 w-4 rounded-lg" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.IconButtonControl, { borderColor: "border-transparent", icon: pro_solid_svg_icons_1.faCircleInfo, iconColor: "text-white", tooltip: {
                                        label: "Toggle 'Required' ON if customers must select a specific number of add-ons per variant. If 'Required' is OFF and no amount is specified, the add-on is optional with no recommended quantity. If an amount is specified while 'Required' is OFF, it suggests a preferred quantity for users to consider.",
                                        placement: "top",
                                        colors: {
                                            backgroundColor: "bg-dark",
                                            textColor: "text-white",
                                        },
                                    }, onClick: function () { }, size: "medium" }) })))] }), index));
                }) })), (_b = selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.add_ons) === null || _b === void 0 ? void 0 : _b.map(function (product, index) {
                return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "grid grid-cols-10 gap-2 mt-3 items-center" }, { children: variantAddOnsTableColumns === null || variantAddOnsTableColumns === void 0 ? void 0 : variantAddOnsTableColumns.map(function (_a, i) {
                        var displayKey = _a.displayKey;
                        if (displayKey === "amount_per_variant") {
                            return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "col-span-2 flex flex-row gap-4 items-center" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "w-24" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { value: product.amount_per_variant, size: "small", type: "number", onChange: function (v) {
                                                var updatedProduct = __assign(__assign({}, product), { amount_per_variant: v });
                                                var updatedAddOns = selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.add_ons.map(function (item) {
                                                    if (item._id === product._id) {
                                                        return updatedProduct;
                                                    }
                                                    return item;
                                                });
                                                setSelectedItemData(__assign(__assign({}, selectedItemData), { add_ons: updatedAddOns }));
                                                setShowSaveChangesLabel(true);
                                            } }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.IconButtonControl, { size: "2xsmall", icon: pro_solid_svg_icons_1.faTrash, backgroundColor: "bg-red-400", iconColor: "text-white", borderColor: "border-transparent", onClick: function (v) {
                                                var _a;
                                                var filteredProducts = (_a = selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.add_ons) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return (item === null || item === void 0 ? void 0 : item._id) !== (product === null || product === void 0 ? void 0 : product._id); });
                                                setSelectedItemData(__assign(__assign({}, selectedItemData), { add_ons: __spreadArray([], filteredProducts, true) }));
                                                setShowSaveChangesLabel(true);
                                            } }) }))] }), i));
                        }
                        if (displayKey === "required") {
                            return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "col-span-2 break-words items-center" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { size: "xsmall", value: product === null || product === void 0 ? void 0 : product.is_required, onChange: function (v) {
                                        var updatedProduct = __assign(__assign({}, product), { is_required: v });
                                        var updatedAddOns = selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.add_ons.map(function (item) {
                                            if (item._id === product._id) {
                                                return updatedProduct;
                                            }
                                            return item;
                                        });
                                        setSelectedItemData(__assign(__assign({}, selectedItemData), { add_ons: updatedAddOns }));
                                        setShowSaveChangesLabel(true);
                                    } }) }), i));
                        }
                        return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "col-span-2 break-words items-center" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: product[displayKey], size: "xsmall", textColor: "text-black" }) }), i));
                    }) }), index));
            }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col items-end" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "w-[100px] mt-8" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { iconColor: "text-white", label: "Save", size: "medium", backgroundColor: "bg-primary", textColor: "text-white", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                var oldRoot, updateItem, modified, request, response;
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            oldRoot = __assign(__assign({}, selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.root), { variations: (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.root.variations) ? selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.root.variations : [] });
                                            updateItem = function (node, ref, modifiedData) {
                                                var _a;
                                                if ((node === null || node === void 0 ? void 0 : node.ref) === ref) {
                                                    node = modifiedData;
                                                    return node;
                                                }
                                                if ((_a = node === null || node === void 0 ? void 0 : node.variations) === null || _a === void 0 ? void 0 : _a.length) {
                                                    node.variations = node.variations.map(function (item) { return updateItem(item, ref, modifiedData); });
                                                }
                                                return node;
                                            };
                                            modified = updateItem(oldRoot, selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.ref, selectedItemData);
                                            (_a = modified === null || modified === void 0 ? void 0 : modified.variations) === null || _a === void 0 ? void 0 : _a.forEach(function (item) {
                                                if (item === null || item === void 0 ? void 0 : item.root) {
                                                    delete item.root;
                                                }
                                            });
                                            request = {
                                                variance_id: modified === null || modified === void 0 ? void 0 : modified._id,
                                                node: {
                                                    add_ons: modified === null || modified === void 0 ? void 0 : modified.add_ons,
                                                    client_id: modified === null || modified === void 0 ? void 0 : modified.client_id,
                                                    product_id: modified === null || modified === void 0 ? void 0 : modified.product_id,
                                                    status: modified === null || modified === void 0 ? void 0 : modified.status,
                                                    type: modified === null || modified === void 0 ? void 0 : modified.type,
                                                    title: modified === null || modified === void 0 ? void 0 : modified.title,
                                                    price: modified === null || modified === void 0 ? void 0 : modified.price,
                                                    sku: modified === null || modified === void 0 ? void 0 : modified.sku,
                                                    pid: modified === null || modified === void 0 ? void 0 : modified.pid,
                                                    on_promotion: modified === null || modified === void 0 ? void 0 : modified.on_promotion,
                                                    promotion_price: modified === null || modified === void 0 ? void 0 : modified.promotion_price,
                                                    image_url: modified === null || modified === void 0 ? void 0 : modified.image_url,
                                                    variations: modified === null || modified === void 0 ? void 0 : modified.variations,
                                                },
                                            };
                                            return [4 /*yield*/, (0, update_product_variances_1.update_product_variances)(request)];
                                        case 1:
                                            response = _b.sent();
                                            if (response.success) {
                                                onTaskComplete();
                                                setShowSaveChangesLabel(false);
                                                dispatch((0, main_1.setMessageModal)({ title: "Success...", messages: [response.message] }));
                                                setTimeout(function () {
                                                    dispatch((0, main_1.setMessageModal)(null));
                                                    dispatch((0, main_1.setContentModal)(null));
                                                }, 1000);
                                            }
                                            else {
                                                onTaskComplete();
                                                setShowSaveChangesLabel(false);
                                                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong updating variance on add_ons component!", messages: [response.message] }));
                                            }
                                            return [2 /*return*/];
                                    }
                                });
                            }); } }) })), showSaveChangesLabel && ((0, jsx_runtime_1.jsx)("div", __assign({ className: "mt-2" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Save Changes...", size: "xsmall", textColor: "text-red-600" }) })))] }))] })));
};
exports.default = PimProductVariationAddOns;
