"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductRequests = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("@nextui-org/react");
var tableContext_1 = require("../../../../../../contexts/tableContext");
var useColumnsProductRequest_1 = require("./hooks/useColumnsProductRequest");
var table_bottom_content_1 = require("../../../../../../components/table/tableBottomContent/table_bottom_content");
var tabel_cell_1 = require("../../../../../../components/tabel_cell");
var react_redux_1 = require("react-redux");
var get_all_client_product_requests_thunk_1 = require("../../../../../../reducers/slices/supplier_requests/thunks/get_all_client_product_requests_thunk");
var table_top_content_1 = require("../../../../../../components/table/tableTopContent/table_top_content");
var react_2 = require("react");
var doshx_controls_web_1 = require("doshx_controls_web");
var useRowActionsProductRequest_1 = require("./hooks/useRowActionsProductRequest");
var product_request_feedback_1 = require("./product_request_feedback");
var retrieve_marketplace_product_comment_1 = require("../../../../../../api/calls/marketplace-product-requests/retrieve-marketplace-product-comment");
var text_1 = require("../../../../../../controls/text");
var ProductRequests = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.supplierRequestState; }), supplier_products = _a.supplier_products, pagination = _a.pagination, loading = _a.loading;
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var _b = (0, react_2.useState)(false), isModalOpen = _b[0], setIsModalOpen = _b[1];
    var _c = (0, react_2.useState)(null), selectedProduct = _c[0], setSelectedProduct = _c[1];
    var _d = (0, react_2.useState)(null), selectedProductComment = _d[0], setSelectedProductComment = _d[1];
    var handleRowAction = function (key) { return __awaiter(void 0, void 0, void 0, function () {
        var product, response;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    product = supplier_products.find(function (item) { return item._id === key; });
                    if (!product) return [3 /*break*/, 2];
                    return [4 /*yield*/, (0, retrieve_marketplace_product_comment_1.get_marketplace_product_comment)({ product_id: product._id })];
                case 1:
                    response = _b.sent();
                    if (response === null || response === void 0 ? void 0 : response.success) {
                        setSelectedProductComment((_a = response === null || response === void 0 ? void 0 : response.content) === null || _a === void 0 ? void 0 : _a.comment);
                    }
                    _b.label = 2;
                case 2:
                    ;
                    setSelectedProduct(product);
                    setIsModalOpen(true);
                    return [2 /*return*/];
            }
        });
    }); };
    var _e = (0, useColumnsProductRequest_1.useColumnsProductRequest)(), columns = _e.columns, excludeSortColumns = _e.excludeSortColumns, headerColumns = _e.headerColumns, initialSortDescriptor = _e.initialSortDescriptor, onVisibleColumnsChanged = _e.onVisibleColumnsChanged, setTableColumnConfig = _e.setTableColumnConfig, tableColumnConfig = _e.tableColumnConfig, visibleColumns = _e.visibleColumns;
    var rowActions = (0, useRowActionsProductRequest_1.useRowActionsProductRequest)().rowActions;
    var renderCellViews = (0, react_2.useCallback)(function (cellValue) {
        var _a, _b;
        var commonTextStyles = "text-small capitalize text-default-foreground";
        var nowrapTextStyles = "text-nowrap text-small capitalize text-default-foreground";
        return [
            {
                key: "image_url",
                content: ((0, jsx_runtime_1.jsx)(react_1.User, { avatarProps: {
                        radius: "lg",
                        src: cellValue,
                    }, classNames: {
                        name: "text-default-foreground",
                        description: "text-default-500",
                    }, name: "" })),
            },
            {
                key: "marketplace_name",
                content: (0, jsx_runtime_1.jsx)("div", __assign({ className: nowrapTextStyles }, { children: cellValue })),
            },
            {
                key: "sku",
                content: (0, jsx_runtime_1.jsx)("div", { children: cellValue }),
            },
            {
                key: "pid",
                content: (0, jsx_runtime_1.jsx)("div", { children: cellValue }),
            },
            {
                key: "title",
                content: (0, jsx_runtime_1.jsx)("div", __assign({ className: nowrapTextStyles }, { children: cellValue })),
            },
            {
                key: "price",
                content: ((0, jsx_runtime_1.jsx)("div", __assign({ className: nowrapTextStyles }, { children: doshx_controls_web_1.Utilities.formatMoney({
                        symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                        value: (_a = cellValue === null || cellValue === void 0 ? void 0 : cellValue.toString()) !== null && _a !== void 0 ? _a : "0",
                        symbolSeparation: true,
                    }) }))),
            },
            {
                key: "category",
                content: (0, jsx_runtime_1.jsx)("div", __assign({ className: commonTextStyles }, { children: (_b = cellValue === null || cellValue === void 0 ? void 0 : cellValue.name) !== null && _b !== void 0 ? _b : "N/A" })),
            },
            {
                key: "published",
                content: (0, jsx_runtime_1.jsx)("div", __assign({ className: nowrapTextStyles }, { children: cellValue ? "Yes" : "No" })),
            },
            {
                key: "marketplace_product_status",
                content: ((0, jsx_runtime_1.jsx)(react_1.Chip, __assign({ className: "text-xs capitalize text-default-foreground", size: "md", variant: "flat", color: cellValue === "PENDING" ? "warning" : cellValue === "APPROVED" ? "success" : "danger" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "p-1" }, { children: cellValue })) }))),
            },
            { key: "actions", content: (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: cellValue })) },
        ];
    }, [client]);
    var getProducts = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, get_all_client_product_requests_thunk_1.get_all_client_product_requests_thunk)(__assign(__assign({}, query), { is_supplier: client.supplier ? true : false })))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-wrap items-center gap-2 mb-4" }, { children: [(0, jsx_runtime_1.jsx)(text_1.TextControl, { value: "Product Requests", className: "text-xl sm:text-2xl font-bold leading-tight" }), (0, jsx_runtime_1.jsx)(react_1.Chip, __assign({ className: "hidden sm:flex items-center text-default-500", size: "sm", variant: "flat" }, { children: pagination === null || pagination === void 0 ? void 0 : pagination.countTotal }))] })), (0, jsx_runtime_1.jsx)(tableContext_1.TableContextProvider, __assign({ allowSorting: false, columns: columns, defaultQueryParams: pagination, excludeSortColumns: excludeSortColumns, headerColumns: headerColumns, initialSortDescriptor: initialSortDescriptor, onPageChange: getProducts, onVisibleColumnsChanged: onVisibleColumnsChanged, setTableConfig: setTableColumnConfig, tableColumnConfig: tableColumnConfig, pagination: pagination, visibleColumns: visibleColumns }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col" }, { children: [(0, jsx_runtime_1.jsxs)(react_1.Table, __assign({ isHeaderSticky: true, "aria-label": "Products", bottomContent: (0, jsx_runtime_1.jsx)(table_bottom_content_1.TableBottomContent, {}), bottomContentPlacement: "outside", classNames: {
                                td: "before:bg-transparent",
                            }, className: "overflow-hidden", topContent: (0, jsx_runtime_1.jsx)(table_top_content_1.TableTopContent, {}), sortDescriptor: initialSortDescriptor, topContentPlacement: "outside", onRowAction: handleRowAction }, { children: [(0, jsx_runtime_1.jsx)(react_1.TableHeader, __assign({ columns: headerColumns }, { children: function (column) { return ((0, jsx_runtime_1.jsx)(react_1.TableColumn, __assign({ align: column.uid === "actions" ? "end" : "start", className: (0, react_1.cn)([column.uid === "actions" ? "flex items-center justify-end px-[20px]" : ""]) }, { children: column.name }), column.uid)); } })), (0, jsx_runtime_1.jsx)(react_1.TableBody, __assign({ isLoading: loading, emptyContent: "This table is empty.", items: supplier_products, loadingContent: (0, jsx_runtime_1.jsx)(react_1.Spinner, { label: "Loading..." }) }, { children: function (item) {
                                        return ((0, jsx_runtime_1.jsx)(react_1.TableRow, { children: function (columnKey) { return ((0, jsx_runtime_1.jsx)(react_1.TableCell, { children: (0, jsx_runtime_1.jsx)(tabel_cell_1.TableCellComponent, { columnRecord: item, columnKey: columnKey, renderCellViews: renderCellViews, rowActions: rowActions }) })); } }, item === null || item === void 0 ? void 0 : item._id));
                                    } }))] })), (0, jsx_runtime_1.jsx)(react_1.Modal, __assign({ isOpen: isModalOpen, onClose: function () { return setIsModalOpen(false); }, size: "2xl", className: "max-h-[120vh] overflow-y-auto" }, { children: (0, jsx_runtime_1.jsxs)(react_1.ModalContent, { children: [(0, jsx_runtime_1.jsx)(react_1.ModalHeader, __assign({ className: "font-semibold" }, { children: "Product request feedback" })), (0, jsx_runtime_1.jsx)(react_1.ModalBody, __assign({ className: "max-h-[70vh] overflow-y-auto" }, { children: (0, jsx_runtime_1.jsx)(product_request_feedback_1.ProductRequestFeedback, { product: selectedProduct, comment: selectedProductComment, closeModal: function () { return setIsModalOpen(false); } }) })), (0, jsx_runtime_1.jsx)(react_1.ModalFooter, {})] }) }))] })) }))] }));
};
exports.ProductRequests = ProductRequests;
