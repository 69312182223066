"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrdersTablePage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var react_2 = require("@nextui-org/react");
var table_bottom_content_1 = require("../../../../../../components/table/tableBottomContent/table_bottom_content");
var table_cell_1 = require("../../../../../../components/table/tableCell/table_cell");
var table_top_content_1 = require("../../../../../../components/table/tableTopContent/table_top_content");
var tableContext_1 = require("../../../../../../contexts/tableContext");
var routes_1 = require("../../../../../../constants/routes");
var console_1 = require("../../../../../../reducers/slices/console");
var orders_1 = require("../../../../../../reducers/slices/orders/orders");
var order_list_thunk_1 = require("../../../../../../reducers/slices/orders/thunks/order_list_thunk");
var useColumns_1 = require("./hooks/useColumns");
var useFilterActions_1 = require("./hooks/useFilterActions");
var useRenderCellViews_1 = require("./hooks/useRenderCellViews");
var useRowActions_1 = require("./hooks/useRowActions");
function OrdersTablePage() {
    var _this = this;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var tableRef = (0, react_1.useRef)();
    var pageTitle = (0, react_redux_1.useSelector)(function (state) { return state.consoleState; }).pageTitle;
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.orderState; }), orders = _a.orders, pagination = _a.pagination, isLoading = _a.isLoading;
    var fetchOrders = function (query) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, order_list_thunk_1.order_list_thunk)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var _b = (0, useColumns_1.useColumns)(), columns = _b.columns, excludeSortColumns = _b.excludeSortColumns, headerColumns = _b.headerColumns, initialSortDescriptor = _b.initialSortDescriptor, onVisibleColumnsChanged = _b.onVisibleColumnsChanged, setTableColumnConfig = _b.setTableColumnConfig, tableColumnConfig = _b.tableColumnConfig, visibleColumns = _b.visibleColumns;
    var filterActions = (0, useFilterActions_1.useFilterActions)({ orders: orders }).filterActions;
    var renderCellViews = (0, useRenderCellViews_1.useRenderCellViews)().renderCellViews;
    var rowActions = (0, useRowActions_1.useRowActions)().rowActions;
    var ordersToRender = (0, react_1.useMemo)(function () {
        return orders.map(function (order) {
            var _a, _b;
            return (__assign(__assign({}, order), { branch_name: (_b = (_a = order.branch) === null || _a === void 0 ? void 0 : _a.display_name) !== null && _b !== void 0 ? _b : "", customer: "".concat(order.name, " ").concat(order.surname) }));
        });
    }, [orders]);
    var handleRowAction = (0, react_1.useCallback)(function (key) {
        var _a;
        dispatch((0, orders_1.setSelectedOrder)((_a = orders.find(function (_a) {
            var _id = _a._id;
            return _id === key.toString();
        })) !== null && _a !== void 0 ? _a : {}));
        navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.ORDERS_ROUTE).concat(routes_1.ORDER_ROUTE, "/").concat(key));
    }, [navigate]);
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Orders"));
    }, []);
    return ((0, jsx_runtime_1.jsxs)(tableContext_1.TableContextProvider, __assign({ columns: columns, defaultQueryParams: pagination, excludeSortColumns: excludeSortColumns, filterActions: filterActions, headerColumns: headerColumns, initialSortDescriptor: initialSortDescriptor, onPageChange: fetchOrders, onVisibleColumnsChanged: onVisibleColumnsChanged, setTableConfig: setTableColumnConfig, tableColumnConfig: tableColumnConfig, pagination: pagination, visibleColumns: visibleColumns }, { children: [(0, jsx_runtime_1.jsx)("h1", __assign({ className: "text-2xl font-[700] leading-[32px] mb-4" }, { children: pageTitle })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-full relative overflow-hidden" }, { children: (0, jsx_runtime_1.jsxs)(react_2.Table, __assign({ "aria-label": "Quotes", bottomContent: (0, jsx_runtime_1.jsx)(table_bottom_content_1.TableBottomContent, {}), bottomContentPlacement: "outside", classNames: { td: "before:bg-transparent" }, isHeaderSticky: true, onRowAction: handleRowAction, ref: tableRef, selectionMode: "single", topContent: (0, jsx_runtime_1.jsx)(table_top_content_1.TableTopContent, {}), topContentPlacement: "outside" }, { children: [(0, jsx_runtime_1.jsx)(react_2.TableHeader, __assign({ columns: headerColumns }, { children: function (column) { return (0, jsx_runtime_1.jsx)(react_2.TableColumn, { children: column.name }, column.uid); } })), (0, jsx_runtime_1.jsx)(react_2.TableBody, __assign({ isLoading: isLoading, emptyContent: "This table is empty.", items: ordersToRender, loadingContent: (0, jsx_runtime_1.jsx)(react_2.Spinner, { label: "Loading..." }) }, { children: function (item) { return ((0, jsx_runtime_1.jsx)(react_2.TableRow, { children: function (columnKey) { return ((0, jsx_runtime_1.jsx)(react_2.TableCell, { children: (0, jsx_runtime_1.jsx)(table_cell_1.TableCellComponent, { columnRecord: item, columnKey: columnKey, renderCellViews: renderCellViews, rowActions: rowActions }) })); } }, item._id)); } }))] })) }))] })));
}
exports.OrdersTablePage = OrdersTablePage;
