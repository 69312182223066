"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRowActions = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var react_2 = require("@nextui-org/react");
var delete_1 = require("../../../../../../../../../../components/icons/delete");
var branch_1 = require("../../../../../../../../../../reducers/slices/branch/branch");
function useRowActions() {
    var dispatch = (0, react_redux_1.useDispatch)();
    var selectedBranch = (0, react_redux_1.useSelector)(function (state) { return state.branchState; }).selectedBranch;
    var eyesRef = (0, react_1.useRef)(null);
    var getProps = (0, react_2.useButton)({ ref: eyesRef }).getButtonProps;
    var deleteAction = (0, react_1.useCallback)(function (entry) { return function () {
        var newDeliveryHours = __spreadArray([], selectedBranch.delivery.delivery_hours.filter(function (_a) {
            var _id = _a._id;
            return _id !== entry._id;
        }), true);
        dispatch((0, branch_1.setSelectedBranch)(__assign(__assign({}, selectedBranch), { delivery: __assign(__assign({}, selectedBranch.delivery), { delivery_hours: newDeliveryHours }) })));
    }; }, [selectedBranch]);
    var rowActions = (0, react_1.useCallback)(function (row) { return [
        {
            element: (0, jsx_runtime_1.jsx)(delete_1.DeleteFilledIcon, __assign({}, getProps(), { onClick: deleteAction(row), className: "cursor-pointer text-default-400", height: 18, width: 18 })),
            key: "delete",
        },
    ]; }, [eyesRef, getProps, deleteAction]);
    return (0, react_1.useMemo)(function () { return ({
        rowActions: rowActions,
    }); }, [rowActions]);
}
exports.useRowActions = useRowActions;
