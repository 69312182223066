"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTableContext = exports.TableContextProvider = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var createUseContext_1 = require("../helpers/createUseContext");
var useSearchDebounce_1 = require("../hooks/useSearchDebounce");
var drawerContext_1 = require("./drawerContext");
var filtersContext_1 = require("./filtersContext");
var paginationContext_1 = require("./paginationContext");
function useTableContextData(_a) {
    var _b = _a.allowSorting, allowSorting = _b === void 0 ? true : _b, columns = _a.columns, _c = _a.defaultQueryParams, defaultQueryParams = _c === void 0 ? {} : _c, _d = _a.excludeSortColumns, excludeSortColumns = _d === void 0 ? [] : _d, headerColumns = _a.headerColumns, initialSortDescriptor = _a.initialSortDescriptor, onPageChange = _a.onPageChange, onVisibleColumnsChanged = _a.onVisibleColumnsChanged, setTableConfig = _a.setTableConfig, tableColumnConfig = _a.tableColumnConfig, visibleColumns = _a.visibleColumns;
    var _e = (0, useSearchDebounce_1.useSearchDebounce)(), search = _e[0], setSearch = _e[1];
    var _f = (0, react_1.useState)(defaultQueryParams), queryParams = _f[0], setQueryParams = _f[1];
    var _g = (0, react_1.useState)(initialSortDescriptor), sortDescriptor = _g[0], setSortDescriptor = _g[1];
    var onQueryParamsChanged = (0, react_1.useCallback)(function (params) {
        setQueryParams(params);
    }, []);
    var onSearchChanged = (0, react_1.useCallback)(function (searchString) {
        setSearch(searchString);
    }, []);
    var onSortChanged = (0, react_1.useCallback)(function (sort) {
        setSortDescriptor(sort);
    }, []);
    (0, react_1.useEffect)(function () {
        if (!!search) {
            setQueryParams(__assign(__assign({}, queryParams), { search_string: search }));
        }
        else {
            setQueryParams(__assign({}, queryParams));
        }
    }, [search]);
    (0, react_1.useEffect)(function () {
        if (allowSorting) {
            var order_by = sortDescriptor.direction === "ascending" ? 1 : -1;
            if (!!sortDescriptor) {
                setQueryParams(__assign(__assign({}, queryParams), { sort_by: sortDescriptor.column.toString(), order_by: order_by.toString() }));
            }
            else {
                setQueryParams(__assign({}, queryParams));
            }
        }
    }, [allowSorting, sortDescriptor]);
    (0, react_1.useEffect)(function () {
        if (!!onPageChange) {
            if (typeof onPageChange === "function") {
                onPageChange(queryParams);
            }
        }
    }, [queryParams]);
    return (0, react_1.useMemo)(function () { return ({
        allowSorting: allowSorting,
        columns: columns,
        excludeSortColumns: excludeSortColumns,
        headerColumns: headerColumns,
        onQueryParamsChanged: onQueryParamsChanged,
        onSearchChanged: onSearchChanged,
        onSortChanged: onSortChanged,
        onVisibleColumnsChanged: onVisibleColumnsChanged,
        queryParams: queryParams,
        search: search,
        setTableConfig: setTableConfig,
        sortDescriptor: sortDescriptor,
        tableColumnConfig: tableColumnConfig,
        visibleColumns: visibleColumns,
    }); }, [
        allowSorting,
        columns,
        excludeSortColumns,
        headerColumns,
        onQueryParamsChanged,
        onSearchChanged,
        onSortChanged,
        onVisibleColumnsChanged,
        queryParams,
        search,
        setTableConfig,
        sortDescriptor,
        tableColumnConfig,
        visibleColumns,
    ]);
}
var TableContext = (0, react_1.createContext)(null);
TableContext.displayName = "TableContext";
function TableContextProvider(props) {
    var children = props.children, filterActions = props.filterActions, drawerContent = props.drawerContent, pagination = props.pagination;
    return ((0, jsx_runtime_1.jsx)(TableContext.Provider, __assign({ value: useTableContextData(props) }, { children: (0, jsx_runtime_1.jsx)(filtersContext_1.FiltersContextProvider, __assign({ filterActions: filterActions }, { children: (0, jsx_runtime_1.jsx)(drawerContext_1.DrawerContextProvider, __assign({ drawerContent: drawerContent }, { children: (0, jsx_runtime_1.jsx)(paginationContext_1.PaginationContextProvider, __assign({ pagination: pagination }, { children: children })) })) })) })));
}
exports.TableContextProvider = TableContextProvider;
exports.useTableContext = (0, createUseContext_1.createUseContext)(TableContext);
