"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRowActions = void 0;
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var react_2 = require("@nextui-org/react");
var routes_1 = require("../../../../../../../constants/routes");
function useRowActions() {
    var navigate = (0, react_router_dom_1.useNavigate)();
    var eyesRef = (0, react_1.useRef)(null);
    var getEyesProps = (0, react_2.useButton)({ ref: eyesRef }).getButtonProps;
    var viewAction = (0, react_1.useCallback)(function (order) { return function () {
        navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.ORDERS_ROUTE).concat(routes_1.ORDER_ROUTE, "/").concat(order._id));
    }; }, [navigate]);
    var rowActions = (0, react_1.useCallback)(function (row) { return [
    // {
    //   element: <EyeFilledIcon {...getEyesProps()} onClick={viewAction(row)} className="cursor-pointer text-default-400" height={18} width={18} />,
    //   key: "view",
    // },
    ]; }, [eyesRef, getEyesProps, viewAction]);
    return (0, react_1.useMemo)(function () { return ({
        rowActions: rowActions,
    }); }, [rowActions]);
}
exports.useRowActions = useRowActions;
