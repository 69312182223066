"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dealsTableColumns = exports.dealCategoriesColumns = exports.INITIAL_VISIBLE_COLUMNS = exports.dealBranchColumns = exports.MANAGERS_DEAL_TYPES = exports.DEAL_TYPES = exports.GENDER = exports.DAYS_APPLICABLE = exports.APPLY_TO_BASKET = exports.PACKAGE_APPLY_TO = exports.COMBO_ITEM_APPLY_TO = exports.FREE_ITEM_APPLY_TO = exports.APPLY_TO = exports.APPLY_TO_DISCOUNT_DEAL = exports.STATUSES = exports.FREE_ITEM_DISCOUNT_TYPE = exports.DISCOUNT_TYPE = exports.GROUPS = exports.MANAGERS_USAGE = exports.USAGE = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
exports.USAGE = [
    { label: "Unlimited", value: "UNLIMITED" },
    { label: "Fixed", value: "FIXED" },
];
exports.MANAGERS_USAGE = [{ label: "Fixed", value: "FIXED" }];
exports.GROUPS = [
    { label: "Loyalty Members", value: "LOYALTY" },
    { label: "All Users", value: "ALL" },
];
exports.DISCOUNT_TYPE = [
    { label: "Percentage", value: "PERCENTAGE" },
    { label: "Value", value: "VALUE" },
];
exports.FREE_ITEM_DISCOUNT_TYPE = [{ label: "Percentage", value: "PERCENTAGE" }];
exports.STATUSES = [
    { label: "Active", value: "ACTIVE" },
    { label: "Draft", value: "DRAFT" },
    { label: "Inactive", value: "INACTIVE" },
];
exports.APPLY_TO_DISCOUNT_DEAL = [
    { label: "Products", value: "PRODUCT" },
    // { label: "Categories", value: "CATEGORY" }, // commenting this out until we know what needs to happen when selected a Category.
    { label: "Baskets", value: "BASKET" },
];
exports.APPLY_TO = [
    { label: "Products", value: "PRODUCT" },
    // { label: "Categories", value: "CATEGORY" }, // commenting this out until we know what needs to happen when selected a Category.
];
exports.FREE_ITEM_APPLY_TO = [{ label: "Products", value: "PRODUCT" }];
exports.COMBO_ITEM_APPLY_TO = [{ label: "Combo", value: "COMBO" }];
exports.PACKAGE_APPLY_TO = [{ label: "Package", value: "PACKAGE" }];
exports.APPLY_TO_BASKET = [{ label: "Baskets", value: "BASKET" }];
exports.DAYS_APPLICABLE = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
exports.GENDER = [
    { label: "Male", value: "MALE" },
    { label: "Female", value: "FEMALE" },
];
exports.DEAL_TYPES = [
    { label: "Discount Deal", value: "DISCOUNT" },
    { label: "Progressive Discount Deal", value: "NTH" },
    { label: "Free Item Deal", value: "FREE-ITEM" },
    { label: "Combo Deal", value: "COMBO" },
    { label: "Package Deal", value: "PACKAGE" },
    { label: "Buy For Deal", value: "BUY-FOR" },
    // { label: "Buy One Get One Deal", value: "BUY-ONE-GET-ONE" },
    // { label: "Buy Plus Deal", value: "BUY-PLUS" },
    // { label: "Bundle Deal", value: "BUNDLE" },
    // { label: "Free Shipping Deal", value: "FREE-SHIPPING" },
];
exports.MANAGERS_DEAL_TYPES = [
    { label: "Discount Deal", value: "DISCOUNT" },
    { label: "Free Item Deal", value: "FREE-ITEM" },
    { label: "Combo Deal", value: "COMBO" },
    { label: "Package Deal", value: "PACKAGE" },
    { label: "Buy For Deal", value: "BUY-FOR" },
];
exports.dealBranchColumns = [
    {
        displayKey: "display_name",
        label: "Branch Name",
    },
    {
        displayKey: "branch_number",
        label: "Branch Number",
    },
    {
        displayKey: "contact_number",
        label: "Contact Number",
    },
    {
        displayKey: "email",
        label: "Email Address",
    },
    {
        displayKey: "offline",
        label: "Online",
        formatter: function (branch) {
            if (branch === null || branch === void 0 ? void 0 : branch.offline) {
                return (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { className: "text-disabled", icon: pro_solid_svg_icons_1.faCircleMinus });
            }
            else {
                return (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { className: "text-success", icon: pro_solid_svg_icons_1.faCircleCheck });
            }
        },
    },
];
exports.INITIAL_VISIBLE_COLUMNS = ["image_url", "sku", "pid", "title", "price", "required_quantity", "availability"];
exports.dealCategoriesColumns = [
    {
        displayKey: "name",
        label: "Category Name",
    },
    {
        displayKey: "image_url",
        label: "Image",
        formatter: function (category) {
            if (category) {
                return (0, jsx_runtime_1.jsx)("img", { className: "w-10 aspect-square rounded-lg", src: category === null || category === void 0 ? void 0 : category.image_url });
            }
            else {
                return "";
            }
        },
    },
];
exports.dealsTableColumns = [
    {
        displayKey: "Image",
        label: "Image",
        formatter: function (deal) {
            if (deal) {
                return (0, jsx_runtime_1.jsx)("img", { className: "w-10 aspect-square rounded-lg", src: deal === null || deal === void 0 ? void 0 : deal.image_url });
            }
            else {
                return "";
            }
        },
    },
    {
        displayKey: "name",
        label: "Name",
    },
    {
        displayKey: "configuration",
        label: "Deal Type",
    },
    {
        displayKey: "usage",
        label: "Usage",
    },
    {
        displayKey: "trigger_amount",
        label: "Trigger Amount",
    },
    {
        displayKey: "discount",
        label: "Discount",
    },
    {
        displayKey: "type",
        label: "Type",
    },
    {
        displayKey: "status",
        label: "Status",
    },
    {
        displayKey: "apply_to",
        label: "Apply To",
        formatter: function (deal) {
            if (deal) {
                return deal === null || deal === void 0 ? void 0 : deal.apply_to;
            }
            else {
                return "";
            }
        },
    },
    {
        displayKey: "createdAt",
        label: "Date",
        formatter: function (deal) {
            if (deal) {
                return doshx_controls_web_1.Utilities.formatDateTime(deal === null || deal === void 0 ? void 0 : deal.createdAt, "YYYY-MM-DD");
            }
            else {
                return "";
            }
        },
    },
    {
        displayKey: "code",
        label: "Code",
    },
    {
        displayKey: "app_only",
        label: "App Only",
        formatter: function (deal) {
            if (!deal.app_only) {
                return "No";
            }
            else {
                return "Yes";
            }
        },
    },
];
