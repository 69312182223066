"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialSqlDatabaseIntValues = exports.SQL_DATABASE = void 0;
exports.SQL_DATABASE = "SQL_DATABASE";
exports.initialSqlDatabaseIntValues = {
    type: exports.SQL_DATABASE,
    data: {
        host: "",
        port: "",
        username: "",
        password: "",
        database: "",
        table: "",
    },
};
