"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFiltersContext = exports.FiltersContextProvider = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_2 = require("@nextui-org/react");
var createUseContext_1 = require("../helpers/createUseContext");
var tableContext_1 = require("./tableContext");
function useFiltersContextData(_a) {
    var _b = _a.filterActions, filterActions = _b === void 0 ? [] : _b;
    var _c = (0, tableContext_1.useTableContext)(), onQueryParamsChanged = _c.onQueryParamsChanged, queryParams = _c.queryParams;
    var _d = (0, react_2.useDisclosure)(), isFiltersPopoverOpen = _d.isOpen, onCloseFilterPopover = _d.onClose, onOpenFilterPopover = _d.onOpen;
    var onApplyFilters = (0, react_1.useCallback)(function () {
        var originalParams = resetQueryparams();
        var filters = filterActions.reduce(function (acc, _a) {
            var key = _a.key, value = _a.value;
            acc[key] = value;
            return acc;
        }, {});
        var flattenedFilters = {};
        Object.entries(filters).forEach(function (_a) {
            var _b;
            var key = _a[0], value = _a[1];
            if (value !== "ALL" && (value === null || value === void 0 ? void 0 : value.length) > 0) {
                flattenedFilters = __assign(__assign({}, flattenedFilters), (_b = {}, _b[key] = value, _b));
            }
        });
        onQueryParamsChanged(__assign(__assign({}, originalParams), flattenedFilters));
        onCloseFilterPopover();
    }, [filterActions, onQueryParamsChanged, queryParams]);
    var onClearFilters = (0, react_1.useCallback)(function () {
        var originalParams = resetQueryparams();
        filterActions = filterActions.map(function (filter) { return (__assign(__assign({}, filter), { value: undefined })); });
        onQueryParamsChanged(__assign({}, originalParams));
        onCloseFilterPopover();
    }, [onQueryParamsChanged]);
    var resetQueryparams = (0, react_1.useCallback)(function () {
        var filters = filterActions.map(function (_a) {
            var _b;
            var key = _a.key, value = _a.value;
            return (_b = {}, _b[key] = value, _b);
        });
        var originalParams = __assign({}, queryParams);
        Object.keys(originalParams).forEach(function (key) {
            filters.forEach(function (kvp) {
                if (Object.keys(kvp).includes(key)) {
                    delete originalParams[key];
                }
            });
        });
        return originalParams;
    }, [filterActions, queryParams]);
    return (0, react_1.useMemo)(function () { return ({
        filterActions: filterActions,
        isFiltersPopoverOpen: isFiltersPopoverOpen,
        onApplyFilters: onApplyFilters,
        onClearFilters: onClearFilters,
        onCloseFilterPopover: onCloseFilterPopover,
        onOpenFilterPopover: onOpenFilterPopover,
    }); }, [filterActions, isFiltersPopoverOpen, onCloseFilterPopover, onOpenFilterPopover]);
}
var FiltersContext = (0, react_1.createContext)(null);
FiltersContext.displayName = "FiltersContext";
function FiltersContextProvider(_a) {
    var children = _a.children, filterActions = _a.filterActions;
    return (0, jsx_runtime_1.jsx)(FiltersContext.Provider, __assign({ value: useFiltersContextData({ filterActions: filterActions }) }, { children: children }));
}
exports.FiltersContextProvider = FiltersContextProvider;
exports.useFiltersContext = (0, createUseContext_1.createUseContext)(FiltersContext);
