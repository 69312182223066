"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PimPromotionComponent = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_2 = require("@nextui-org/react");
var react_redux_1 = require("react-redux");
var PimPromotionComponent = function (_a) {
    var _b, _c;
    var values = _a.values, setValues = _a.setValues, updateValues = _a.updateValues;
    var dispatch = (0, react_redux_1.useDispatch)();
    var fileInputRef = (0, react_1.useRef)(null);
    var _d = (0, react_1.useState)(), lastIndex = _d[0], setLastIndex = _d[1];
    (0, react_1.useEffect)(function () {
        var _a, _b, _c;
        if ((_a = values === null || values === void 0 ? void 0 : values.image_list) === null || _a === void 0 ? void 0 : _a.length) {
            var lastIndex_1 = (_b = values === null || values === void 0 ? void 0 : values.image_list) === null || _b === void 0 ? void 0 : _b.lastIndexOf(values === null || values === void 0 ? void 0 : values.image_list[((_c = values === null || values === void 0 ? void 0 : values.image_list) === null || _c === void 0 ? void 0 : _c.length) - 1]);
            setLastIndex(lastIndex_1);
        }
    }, [(_b = values === null || values === void 0 ? void 0 : values.image_list) === null || _b === void 0 ? void 0 : _b.length]);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsxs)(react_2.Card, __assign({ className: "w-full" }, { children: [(0, jsx_runtime_1.jsx)(react_2.CardHeader, __assign({ className: "flex justify-between items-center px-6 py-4" }, { children: (0, jsx_runtime_1.jsx)("h3", __assign({ className: "text-lg font-semibold" }, { children: "Promotions" })) })), (0, jsx_runtime_1.jsx)(react_2.Divider, {}), (0, jsx_runtime_1.jsx)(react_2.CardBody, __assign({ className: "gap-6 p-6" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "items-center" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "mb-4" }, { children: (0, jsx_runtime_1.jsx)(react_2.Checkbox, __assign({ isSelected: values === null || values === void 0 ? void 0 : values.on_promotion, onValueChange: function (checked) {
                                        updateValues("on_promotion", checked);
                                    }, className: "text-sm" }, { children: "On promotion" })) })), (0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(react_2.Input, { type: "number", label: "Promotional Price", value: (_c = values === null || values === void 0 ? void 0 : values.promotion_price) === null || _c === void 0 ? void 0 : _c.toString(), errorMessage: (values === null || values === void 0 ? void 0 : values.on_promotion) && parseInt(values.promotion_price) <= 0 && "promotional price is required.", classNames: {
                                        input: "border-borders",
                                        inputWrapper: "border-borders",
                                    }, onChange: function (e) {
                                        updateValues("promotion_price", e.target.value);
                                    } }) })] })) }))] })) }));
};
exports.PimPromotionComponent = PimPromotionComponent;
