"use strict";
"use client";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("@nextui-org/react");
var react_2 = __importStar(require("react"));
function clampValue(value, min, max) {
    return Math.min(Math.max(value, min), max);
}
function scaleValue(value, fromRange, toRange) {
    if (toRange === void 0) { toRange = [0, 100]; }
    var fromMin = fromRange[0], fromMax = fromRange[1];
    var toMin = toRange[0], toMax = toRange[1];
    // Scaling factor to map the value from one range to another
    var scale = (toMax - toMin) / (fromMax - fromMin);
    // Applying the scaling factor to the value and adjusting by the minimum of the target range
    return (value - fromMin) * scale + toMin;
}
var PriceSliderPip = function (_a) {
    var _b = _a.animation, animation = _b === void 0 ? "height" : _b, isInRange = _a.isInRange;
    var rand = react_2.default.useMemo(function () { return Math.floor(Math.random() * 100); }, []);
    var height = clampValue(rand, 30, 100) + "%";
    var pip = react_2.default.useMemo(function () {
        if (animation === "height") {
            return ((0, jsx_runtime_1.jsx)("span", { className: "relative h-12 w-1 rounded-full bg-default-100 after:absolute after:bottom-0 after:h-0 after:w-full after:rounded-full after:bg-primary after:transition-all after:!duration-500 after:content-[''] data-[in-range=true]:after:h-full", "data-in-range": isInRange, style: {
                    height: height,
                } }));
        }
        return ((0, jsx_runtime_1.jsx)("span", { className: "h-12 w-1 rounded-full bg-primary transition-all !duration-500", style: {
                background: isInRange ? "hsl(var(--nextui-primary-500))" : "hsl(var(--nextui-default-100))",
                height: height,
            } }));
    }, [isInRange, height, animation]);
    return pip;
};
var PriceSlider = react_2.default.forwardRef(function (_a, ref) {
    var range = _a.range, animation = _a.animation, className = _a.className, onChange = _a.onChange, props = __rest(_a, ["range", "animation", "className", "onChange"]);
    var defaultValue = react_2.default.useMemo(function () { return (range === null || range === void 0 ? void 0 : range.defaultValue) || [0, 1000]; }, [range === null || range === void 0 ? void 0 : range.defaultValue]);
    var _b = react_2.default.useState(defaultValue), value = _b[0], setValue = _b[1];
    (0, react_2.useEffect)(function () {
        if (onChange) {
            onChange(value);
        }
    }, [value]);
    var rangePercentageValue = react_2.default.useMemo(function () {
        var rangeScale = [(range === null || range === void 0 ? void 0 : range.min) || defaultValue[0], (range === null || range === void 0 ? void 0 : range.max) || defaultValue[1]];
        var minPercentage = Math.floor(scaleValue(value[0], rangeScale));
        var maxPercentage = Math.floor(scaleValue(value[1], rangeScale));
        return [minPercentage, maxPercentage];
    }, [value, range === null || range === void 0 ? void 0 : range.min, range === null || range === void 0 ? void 0 : range.max, defaultValue]);
    var rangePips = react_2.default.useMemo(function () {
        return Array.from({ length: 32 }).map(function (_, index) {
            var pipValuePercentage = Math.round(scaleValue(index, [0, 31]));
            var isInRange = pipValuePercentage >= rangePercentageValue[0] + 5 && pipValuePercentage <= rangePercentageValue[1] + 2;
            return (0, jsx_runtime_1.jsx)(PriceSliderPip, { animation: animation, isInRange: isInRange }, index);
        });
    }, [animation, rangePercentageValue]);
    var onMinInputValueChange = react_2.default.useCallback(function (inputValue) {
        var _a;
        var newValue = Number(inputValue);
        var minValue = (_a = range === null || range === void 0 ? void 0 : range.min) !== null && _a !== void 0 ? _a : defaultValue[0];
        if (!isNaN(newValue)) {
            var clampedValue = clampValue(newValue, minValue, value[1]);
            setValue([clampedValue, value[1]]);
        }
    }, [value, range === null || range === void 0 ? void 0 : range.min, defaultValue]);
    var onMaxInputValueChange = react_2.default.useCallback(function (inputValue) {
        var _a;
        var newValue = Number(inputValue);
        var maxValue = (_a = range === null || range === void 0 ? void 0 : range.max) !== null && _a !== void 0 ? _a : defaultValue[1];
        if (!isNaN(newValue) && newValue <= maxValue) {
            setValue([value[0], newValue]);
        }
    }, [value, range === null || range === void 0 ? void 0 : range.max, defaultValue]);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: (0, react_1.cn)("flex flex-col gap-3", className) }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-1" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex h-12 w-full items-end justify-between px-2" }, { children: rangePips })), (0, jsx_runtime_1.jsx)(react_1.Slider, __assign({}, props, { ref: ref, formatOptions: { style: "currency", currency: "USD" }, maxValue: range === null || range === void 0 ? void 0 : range.max, minValue: range === null || range === void 0 ? void 0 : range.min, size: "sm", step: range === null || range === void 0 ? void 0 : range.step, value: value, onChange: function (value) {
                            setValue(value);
                        } }))] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex items-center" }, { children: [(0, jsx_runtime_1.jsx)(react_1.Input, { "aria-label": "Min price", labelPlacement: "outside", startContent: (0, jsx_runtime_1.jsx)("p", __assign({ className: "text-default-400" }, { children: "R" })), type: "number", value: "".concat(value[0]), onValueChange: onMinInputValueChange }), (0, jsx_runtime_1.jsx)(react_1.Divider, { className: "mx-2 w-2" }), (0, jsx_runtime_1.jsx)(react_1.Input, { "aria-label": "Max price", labelPlacement: "outside", startContent: (0, jsx_runtime_1.jsx)("p", __assign({ className: "text-default-400" }, { children: "R" })), type: "number", value: "".concat(value[1]), onValueChange: onMaxInputValueChange })] }))] })));
});
PriceSlider.displayName = "PriceSlider";
exports.default = PriceSlider;
