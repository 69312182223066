"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useColumns = void 0;
var react_1 = require("react");
var usehooks_ts_1 = require("usehooks-ts");
var local_storage_names_1 = require("../../../../../../../../../../constants/local_storage_names");
var INITIAL_VISIBLE_COLUMNS = ["day", "deliveries_from", "deliveries_to", "actions"];
function useColumns() {
    var initialSortDescriptor = { column: "day", direction: "descending" };
    var _a = (0, react_1.useState)("all"), visibleColumns = _a[0], setVisibleColumns = _a[1];
    var _b = (0, usehooks_ts_1.useLocalStorage)(local_storage_names_1.CONSOLE_DELIVERY_HOURS_TABLE_CONFIG, {
        initialVisibleColumns: INITIAL_VISIBLE_COLUMNS,
        visibleColumns: "all",
    }), tableColumnConfig = _b[0], setTableColumnConfig = _b[1];
    var columns = (0, react_1.useMemo)(function () { return [
        { name: "Day", uid: "day", sortable: true },
        { name: "Deliveries From", uid: "deliveries_from", sortable: true },
        { name: "Deliveries To", uid: "deliveries_to", sortable: true },
        { name: "Actions", uid: "actions", sortable: false },
    ]; }, []);
    var excludeSortColumns = (0, react_1.useMemo)(function () { return columns.filter(function (_a) {
        var sortable = _a.sortable;
        return sortable !== true;
    }); }, [columns]);
    var headerColumns = (0, react_1.useMemo)(function () {
        if (!columns || !columns.length) {
            return [];
        }
        return columns
            .map(function (item) {
            if (item.uid === initialSortDescriptor.column) {
                return __assign(__assign({}, item), { sortDirection: initialSortDescriptor.direction });
            }
            return item;
        })
            .filter(function (column) { return (visibleColumns !== "all" ? Array.from(visibleColumns).includes(column.uid) : column); });
    }, [columns, visibleColumns, initialSortDescriptor]);
    var onVisibleColumnsChanged = (0, react_1.useCallback)(function (keys) {
        setVisibleColumns(keys);
    }, []);
    (0, react_1.useEffect)(function () {
        onVisibleColumnsChanged(tableColumnConfig.visibleColumns);
    }, [tableColumnConfig]);
    return (0, react_1.useMemo)(function () { return ({
        columns: columns,
        excludeSortColumns: excludeSortColumns,
        headerColumns: headerColumns,
        initialSortDescriptor: initialSortDescriptor,
        onVisibleColumnsChanged: onVisibleColumnsChanged,
        setTableColumnConfig: setTableColumnConfig,
        tableColumnConfig: tableColumnConfig,
        visibleColumns: visibleColumns,
    }); }, [columns, excludeSortColumns, headerColumns, initialSortDescriptor, onVisibleColumnsChanged, setTableColumnConfig, tableColumnConfig, visibleColumns]);
}
exports.useColumns = useColumns;
