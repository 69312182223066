"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("@iconify/react");
var react_2 = require("@nextui-org/react");
var TagGroupItem = function (_a) {
    var icon = _a.icon, _b = _a.size, size = _b === void 0 ? "md" : _b, props = __rest(_a, ["icon", "size"]);
    var _c = (0, react_2.useCheckbox)(__assign({}, props)), children = _c.children, isSelected = _c.isSelected, isFocusVisible = _c.isFocusVisible, getBaseProps = _c.getBaseProps, getLabelProps = _c.getLabelProps, getInputProps = _c.getInputProps;
    return ((0, jsx_runtime_1.jsxs)("label", __assign({}, getBaseProps(), { children: [(0, jsx_runtime_1.jsx)(react_2.VisuallyHidden, { children: (0, jsx_runtime_1.jsx)("input", __assign({}, getInputProps())) }), (0, jsx_runtime_1.jsx)(react_2.Chip, __assign({ classNames: {
                    base: (0, react_2.cn)({
                        "outline-none ring-2 ring-focus ring-offset-2 ring-offset-background": isFocusVisible,
                        "bg-primary": isSelected,
                    }),
                    content: (0, react_2.cn)("!text-small text-default-400", {
                        "text-primary-foreground": isSelected,
                        "pr-1": !!icon,
                    }),
                }, radius: "sm", size: size, startContent: icon ? ((0, jsx_runtime_1.jsx)(react_1.Icon, { className: (0, react_2.cn)("text-default-400", {
                        "text-primary-foreground": isSelected,
                    }), icon: icon, width: 16 })) : undefined, variant: "flat" }, getLabelProps(), { children: children }))] })));
};
TagGroupItem.displayName = "TagGroupItem";
exports.default = TagGroupItem;
