"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validationConfig = void 0;
exports.validationConfig = {
    name: { required: true },
    image: { required: false },
    gift_card_images: {
        required: true,
    },
};
