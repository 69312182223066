"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_2 = require("@nextui-org/react");
var react_redux_1 = require("react-redux");
var pages_1 = require("../../../../../../constants/pages");
var useSearchDebounce_1 = require("../../../../../../hooks/useSearchDebounce");
var get_client_tags_thunk_1 = require("../../../../../../reducers/slices/products/thunks/get_client_tags_thunk");
var PimAddTagsComponent = function (props) {
    var dispatch = (0, react_redux_1.useDispatch)();
    var values = props.values, setValues = props.setValues;
    var _a = (0, react_1.useState)(""), tag = _a[0], setTag = _a[1];
    var _b = (0, react_1.useState)([]), selectedTag = _b[0], setSelectedTag = _b[1];
    var _c = (0, react_1.useState)(""), addTagErrorText = _c[0], setAddTagErrorText = _c[1];
    var _d = (0, react_1.useState)("FIND"), tagView = _d[0], setTagView = _d[1];
    var _e = (0, useSearchDebounce_1.useSearchDebounce)(), searchAddTags = _e[0], setSearchAddTags = _e[1];
    var clientTags = (0, react_redux_1.useSelector)(function (state) { return state.productState; }).clientTags;
    (0, react_1.useEffect)(function () {
        onSearch(searchAddTags);
    }, [searchAddTags]);
    var getTags = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, get_client_tags_thunk_1.get_client_tags_thunk)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var onSearch = function (search_string) {
        getTags(__assign(__assign({}, pages_1.initialPaginationValues), (search_string && { search_string: search_string })));
    };
    var handleSaveTag = function () {
        if (tag.length) {
            setAddTagErrorText("");
            setValues(__assign(__assign({}, values), { tags: __spreadArray(__spreadArray([], values.tags, true), [tag], false) }));
        }
        else if (selectedTag.length > 0) {
            setValues(__assign(__assign({}, values), { tags: __spreadArray(__spreadArray([], values.tags, true), selectedTag.map(function (x) { return x.name; }), true) }));
        }
        else {
            setAddTagErrorText("Cannot save an empty tag.");
        }
        setTag("");
        setSelectedTag([]);
    };
    var handleRemoveTag = function (tagToRemove) {
        setValues(__assign(__assign({}, values), { tags: values.tags.filter(function (x) { return x !== tagToRemove; }) }));
    };
    return ((0, jsx_runtime_1.jsxs)(react_2.Card, __assign({ className: "w-full" }, { children: [(0, jsx_runtime_1.jsxs)(react_2.CardHeader, __assign({ className: "flex justify-between items-center px-6 py-4" }, { children: [(0, jsx_runtime_1.jsx)("h3", __assign({ className: "text-lg font-semibold" }, { children: "Add tag" })), (0, jsx_runtime_1.jsx)(react_2.Button, __assign({ color: "primary", onClick: handleSaveTag }, { children: "Save" }))] })), (0, jsx_runtime_1.jsx)(react_2.Divider, {}), (0, jsx_runtime_1.jsxs)(react_2.CardBody, __assign({ className: "gap-6 px-6" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex items-center gap-4" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-end gap-4 mb-2" }, { children: [(0, jsx_runtime_1.jsx)(react_2.Button, __assign({ variant: "light", onClick: function () {
                                                setTagView("FIND");
                                                setTag("");
                                                setSelectedTag([]);
                                            } }, { children: "Find Tag" })), (0, jsx_runtime_1.jsx)(react_2.Button, __assign({ variant: "light", onClick: function () {
                                                setTagView("CREATE");
                                                setTag("");
                                                setSelectedTag([]);
                                            } }, { children: "Create Tag" }))] })), tagView === "CREATE" ? ((0, jsx_runtime_1.jsx)(react_2.Input, { label: "Create Tag", value: tag, onChange: function (e) { return setTag(e.target.value); }, errorMessage: addTagErrorText })) : ((0, jsx_runtime_1.jsxs)(react_2.Dropdown, { children: [(0, jsx_runtime_1.jsx)(react_2.DropdownTrigger, { children: (0, jsx_runtime_1.jsx)(react_2.Button, __assign({ variant: "bordered" }, { children: selectedTag.length ? selectedTag.map(function (tag) { return tag.name; }).join(", ") : "Select Tags" })) }), (0, jsx_runtime_1.jsx)(react_2.DropdownMenu, __assign({ selectionMode: "multiple", selectedKeys: selectedTag.map(function (t) { return t.id; }), onSelectionChange: function (keys) {
                                                var selected = Array.from(keys).map(function (id) { return clientTags.find(function (tag) { return tag.id === Number(id); }); });
                                                setSelectedTag(selected);
                                            } }, { children: clientTags.map(function (tag) { return ((0, jsx_runtime_1.jsx)(react_2.DropdownItem, { children: tag.name }, tag.id)); }) }))] }))] })) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "mt-4 flex flex-wrap gap-2" }, { children: values.tags.map(function (tagName, index) { return ((0, jsx_runtime_1.jsx)(react_2.Chip, __assign({ onClose: function () { return handleRemoveTag(tagName); }, variant: "bordered" }, { children: tagName }), index)); }) }))] }))] })));
};
exports.default = PimAddTagsComponent;
